// auth action types

exports.AUTH_LOGIN = "AUTH_LOGIN";
exports.AUTH_LOGOUT = "AUTH_LOGOUT";
exports.AUTH_REGISTER = "AUTH_REGISTER";
exports.SET_CURRENT_USER = "SET_CURRENT_USER";

// error action types
exports.ADD_ERROR = "ADD_ERROR";
exports.REMOVE_ERROR = "REMOVE_ERROR";
