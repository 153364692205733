import React from "react";
// import PropTypes from "prop-types";
import { withFormik } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import { Grid, Button } from "@material-ui/core";

import { ErrorMessage, FieldArray } from "formik";

function certificateForm(props) {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable
  } = props;

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="certificates"
        render={arrayHelpers =>
          values.certificates && values.certificates.length > 0 ? (
            values.certificates.map((certificates, index) => (
              <React.Fragment key={index}>
                <Grid item xl={11} lg={11} md={11} xs={11}>
                  <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Certificate* </label>
                        <input
                          type="text"
                          placeholder="Enter certificate name"
                          className="form-input"
                          name={`certificates[${index}].certificate_name`}
                          {...getFieldProps(
                            `certificates[${index}].certificate_name`
                          )}
                          value={
                            values.certificates[index].certificate_name || ""
                          }
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`certificates[${index}].certificate_name`}
                          className="error-text"
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Year* </label>
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Enter year of certification"
                          name={`certificates[${index}].year_of_certification`}
                          {...getFieldProps(
                            `certificates[${index}].year_of_certification`
                          )}
                          value={
                            values.certificates[index].year_of_certification ||
                            ""
                          }
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`certificates[${index}].year_of_certification`}
                          className="error-text"
                          value={
                            touched.certificates &&
                            touched.certificates[index] &&
                            touched.certificates[index].year_of_certification &&
                            errors.certificates &&
                            errors.certificates[index] &&
                            errors.certificates[index].year_of_certification
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Institute* </label>
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Enter institute name"
                          name={`certificates[${index}].institute_name`}
                          {...getFieldProps(
                            `certificates[${index}].institute_name`
                          )}
                          value={
                            values.certificates[index].institute_name || ""
                          }
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`certificates[${index}].institute_name`}
                          className="error-text"
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group btnConatinerR floatL">
                        <label>&nbsp;</label>
                        <Button
                          className="removeBtn mT8"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          disabled={!isEditable && !selfEditable}
                        >
                          -
                        </Button>
                        {index === values.certificates.length - 1 ? (
                          <Button
                            className="addBtn mT8 mL10"
                            onClick={() =>
                              arrayHelpers.insert(values.certificates.length, {
                                certificate_name: "",
                                year_of_certification: "",
                                institute_name: ""
                              })
                            }
                            disabled={!isEditable && !selfEditable}
                          >
                            +
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                arrayHelpers.push({
                  certificate_name: "",
                  year_of_certification: "",
                  institute_name: ""
                })
              }
              disabled={!isEditable && !selfEditable}
              className="m-auto"
            >
              {/* show this when user has removed all friends from the list */}+
              Add New Certificate
            </Button>
          )
        }
      />
      {(isEditable || selfEditable) && (
        <Grid item xs={12} md={12} lg={12} xl={12} className="">
          <div className="permissionBtn pL0">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="mL20"
              onClick={props.resetForm}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const formikcertificateForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      certificates: formData.certificates || []
    };
    return initialValues;
  },
  enableReinitialize: true,

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.certificates,

  handleSubmit: (values, formikBag) => {
    values.certificates = values.certificates || null;
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },
  displayName: "Certificate"
})(certificateForm);

export default formikcertificateForm;
