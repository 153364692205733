import React, { Component } from "react";
import "./EmployeeList.scss";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import empPic from "./../../assets/images/avatarPic.png";
import axios from "axios";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import CustomDateFormat from "../../components/DateFormat";
import CustomBreadcrumb from "../../components/breadcrumb";
import { Base_URL } from "../../constants";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { selfActionsAllowed, isAuthorised } from "../../utils/accessHelper";

import fileDownload from "js-file-download";
// import moment from "moment";

export default class EmployeeView extends Component {
  state = {
    expanded: {},
    employee: {},
    isLoaded: false,
  };

  async componentDidMount() {
    let { params } = this.props.match;
    let { data } = await axios.get(`/users/view/${params.profileId}`);
    // console.log(data);
    this.setState({ isLoaded: true, employee: data });
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: { ...this.state.expanded, [panel]: isExpanded },
    });
  };

  render() {
    let { expanded, employee, isLoaded } = this.state;

    let empTitle =
      this.props.location.state &&
      this.props.location.state.prevPath &&
      this.props.location.state.prevPath == "employeeReport"
        ? "Employees Reports Dashboard"
        : "Employees";
    let empRoutes =
      this.props.location.state &&
      this.props.location.state.prevPath &&
      this.props.location.state.prevPath == "employeeReport"
        ? "/employees/reports/dashboard/personal"
        : "/employees";

    if (!isLoaded) {
      return (
        <React.Fragment>
          <div className="loader"></div>
          <div className="loaderLogo"></div>
        </React.Fragment>
      );
    } else {
      let employee_pic =
        employee.user && employee.user.avatar
          ? `${Base_URL}${employee.user.avatar}`
          : empPic;
      return (
        <div style={{ maxWidth: "100%" }} className="tableTitle">
          <h1>Employee Profile</h1>
          <div className="oflowH mB15">
            <CustomBreadcrumb
              data={[
                { heading: "Dashboard", link: "/" },
                { heading: empTitle, link: empRoutes },
                {
                  heading: (employee.user && employee.user.name) + " Profile",
                  link: "/employees",
                },
              ]}
            />
            <div className="btnConatinerR">
              {isAuthorised("employee_management.edit") ||
              selfActionsAllowed(employee._id) ? (
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to={`/employees/${employee._id}/edit`}
                >
                  Edit
                </Button>
              ) : null}

              {/* <Button variant="contained" color="primary">
              + Add Employee
            </Button> */}
            </div>
          </div>
          <div className="empCard mB25">
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={3} xs={12} className="empPic">
                <img
                  src={employee_pic}
                  className="xs-img-responsive"
                  alt="User pic"
                />
              </Grid>
              <Grid item xl={9} lg={9} md={9} xs={12}>
                <h2 className="xs-pl15">Personal</h2>
                <Grid container className="xs-pl15">
                  <Grid item xs={6} md={3} lg={3} xl={3} className="empdetails">
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Employee Code
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {employee.employeeCode || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Department
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {" "}
                                {(employee.department &&
                                  employee.department.title) ||
                                  "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Date of Joining
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                <CustomDateFormat
                                  utc_date={employee.dateOfJoining || "NA"}
                                />
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Mobile Number
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {(employee.contactNumber &&
                                  employee.contactNumber.join(", ")) ||
                                  "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3} xl={3} className="empdetails">
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Name
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {(employee.user && employee.user.name) || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Location
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {(employee.location &&
                                  employee.location.title) ||
                                  "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Reporting to
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {(employee.reportingPerson &&
                                  employee.reportingPerson.name) ||
                                  "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Date of Birth
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                <CustomDateFormat
                                  utc_date={employee.dateOfBirth || "NA"}
                                />
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3} xl={3} className="empdetails">
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Official Email
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                <Link href="#">
                                  {" "}
                                  {(employee.user && employee.user.email) ||
                                    "NA"}
                                </Link>
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Designation
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {(employee.designation &&
                                  employee.designation.title) ||
                                  "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Extension Number
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {employee.extensionNumber || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3} xl={3} className="empdetails">
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Marital Status
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {employee.maritalStatus || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Gender
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {employee.gender || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="label" color="textPrimary">
                                Blood Group
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {employee.bloodGroup || "NA"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="mB25">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <div className="empCard policyCard">
                  <h2 className="xs-pl15">Basic</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  {employee.relationPerson &&
                                    employee.relationPerson.relation}{" "}
                                  Name
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.relationPerson &&
                                    employee.relationPerson.name) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  UAN
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {employee.uan || "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Passport Number
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {employee.passport || "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Personal Email
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <Link href="#">
                                    {employee.personalEmail || "NA"}
                                  </Link>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  PAN Number
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {employee.panNumber || "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Aadhar Number
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {employee.aadhar || "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Emergency Contact Name
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.emergency &&
                                    employee.emergency[0] &&
                                    employee.emergency[0].name) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Emergency Contact Mobile
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.emergency &&
                                    employee.emergency[0] &&
                                    employee.emergency[0].contact) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Present Address
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {((employee.presentAddress &&
                                    employee.presentAddress.address1) ||
                                    "") +
                                    " " +
                                    ((employee.presentAddress &&
                                      employee.presentAddress.address2) ||
                                      "") +
                                    " " +
                                    ((employee.presentAddress &&
                                      employee.presentAddress.mobile) ||
                                      "") +
                                    " " +
                                    ((employee.presentAddress &&
                                      employee.presentAddress.pincode) ||
                                      "")}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="empdetails empBox policyBox"
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Permanent Address
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {((employee.permanentAddress &&
                                    employee.permanentAddress.address1) ||
                                    "") +
                                    " " +
                                    ((employee.permanentAddress &&
                                      employee.permanentAddress.address2) ||
                                      "") +
                                    " " +
                                    ((employee.permanentAddress &&
                                      employee.permanentAddress.mobile) ||
                                      "") +
                                    " " +
                                    ((employee.permanentAddress &&
                                      employee.permanentAddress.pincode) ||
                                      "")}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <div className="empCard policyCard">
                  <h2 className="xs-pl15">Medical Policy Detail</h2>
                  <Grid container className="xs-pl15">
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Company
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.medical &&
                                    employee.medical.insuranceProvider) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Policy Number
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.medical &&
                                    employee.medical.policyNumber) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Start Date
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <CustomDateFormat
                                    utc_date={
                                      employee.medical &&
                                      employee.medical.startDate
                                    }
                                  />
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  End Date
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <CustomDateFormat
                                    utc_date={
                                      employee.medical &&
                                      employee.medical.endDate
                                    }
                                  />
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Sum Insured (INR)
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.medical &&
                                    employee.medical.sumAssured) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  TPA
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.medical && employee.medical.tpa) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Policy Type
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  {(employee.medical &&
                                    employee.medical.policyType) ||
                                    "NA"}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Medical Card
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <Link
                                    to="route"
                                    class="anchorCard"
                                    onClick={async () => {
                                      this.setState({ isLoaded: false });
                                      const res = await fetch(
                                        `${Base_URL}medical/card/${employee.employeeCode}`
                                      );
                                      const blob = await res.blob();
                                      this.setState({ isLoaded: true });
                                      fileDownload(
                                        blob,
                                        `${employee.employeeCode}.pdf`
                                      );
                                    }}
                                  >
                                    Click to download
                                  </Link>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Agent Name
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  Manoj Agarwal
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Agent Contact No.
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  9873251178/ 9911137697
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Agent Email
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <a
                                    href={`mailto: Manoj.aggarwal@macinsurance.in`}
                                    style={{ textDecoration: "none" }}
                                    class="anchorCard"
                                  >
                                    Manoj.aggarwal@macinsurance.in
                                  </a>
                                  <br></br>
                                  <a
                                    href={`mailto: macinsurance4u@gmail.com`}
                                    style={{ textDecoration: "none" }}
                                    class="anchorCard"
                                  >
                                    macinsurance4u@gmail.com
                                  </a>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} className="empdetails empBox policyBox">
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="label"
                                  color="textPrimary"
                                >
                                  Policy Wordings
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                >
                                  <a
                                    href={
                                      Base_URL +
                                      "uploads/GHI Presentation Netprophets - RGICL.PPTX"
                                    }
                                    target="_blank"
                                    class="anchorCard"
                                    download
                                  >
                                    Click to download
                                  </a>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} className="pL20">
                      <h3 className="noBdr">Member Details</h3>
                    </Grid>
                    <Grid item xs={12} className="pL20 pR20 medicalTable">
                      <Table aria-label="simple table" className="memberTable">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "70px" }}>
                              S.N.
                            </TableCell>
                            <TableCell>Insured Name</TableCell>
                            {/* <TableCell>Age</TableCell> */}
                            <TableCell>Date of Birth</TableCell>
                            <TableCell>Gender</TableCell>
                            <TableCell>Relationship</TableCell>
                            <TableCell>TPA ID/UHID</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={employee._id}>
                            <TableCell component="th" scope="row">
                              1
                            </TableCell>
                            {/* <TableCell>{row.calories}</TableCell> */}
                            <TableCell>
                              {employee.user && employee.user.name}
                            </TableCell>
                            <TableCell>
                              <CustomDateFormat
                                utc_date={employee.dateOfBirth || "NA"}
                              />
                            </TableCell>
                            <TableCell>{employee.gender}</TableCell>
                            <TableCell>Self</TableCell>
                            <TableCell>
                              {(employee.medical && employee.medical.tpaId) ||
                                "NA"}
                            </TableCell>
                            <TableCell>Active</TableCell>
                          </TableRow>
                          {employee.medical &&
                            employee.medical.familyMembers &&
                            employee.medical.familyMembers.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {index + 2}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <CustomDateFormat
                                    utc_date={row.dob || "NA"}
                                  />
                                </TableCell>
                                <TableCell>{row.gender}</TableCell>
                                <TableCell>{row.relationship}</TableCell>
                                <TableCell>{row.tpaId}</TableCell>
                                <TableCell>{row.status}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mB25">
            <Grid container spacing={2}>
              {/* {employee.educations && employee.educations.length > 0 && ( */}
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <div className="empCard">
                  <h2 className="xs-pl15 mB0">Education</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={12}
                      className="empdetails empBox p-0 accordianContainer"
                    >
                      {employee.educations &&
                        employee.educations.map((item, index) => (
                          <ExpansionPanel
                            key={index}
                            expanded={expanded["panel1" + (index + 1)] || false}
                            onChange={this.handleChange("panel1" + (index + 1))}
                          >
                            <ExpansionPanelSummary
                              className="expandIcon"
                              expandIcon={
                                this.state.expanded["panel1" + (index + 1)] ? (
                                  <RemoveSharpIcon />
                                ) : (
                                  <AddSharpIcon />
                                )
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography component="h3">
                                {item.degree}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Grid container spacing={2}>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <Typography component="label">
                                    Subjects
                                  </Typography>
                                  <Typography
                                    component="span"
                                    color="textPrimary"
                                  >
                                    {item.subject}
                                  </Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} md={2} xs={12}>
                                  <Typography component="label">
                                    Passing Year
                                  </Typography>
                                  <Typography
                                    component="span"
                                    color="textPrimary"
                                  >
                                    {item.passingYear}
                                  </Typography>
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} xs={12}>
                                  <Typography component="label">
                                    Institute
                                  </Typography>
                                  <Typography
                                    component="span"
                                    color="textPrimary"
                                  >
                                    {item.institute}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* )} */}
              {/* {employee.trainings && employee.trainings.length > 0 && ( */}
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <div className="empCard">
                  <h2 className="xs-pl15 mB0">Training</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={12}
                      className="empdetails empBox p-0 accordianContainer"
                    >
                      {employee.trainings &&
                        employee.trainings.map((item, index) => (
                          <ExpansionPanel
                            key={index}
                            expanded={expanded["panel2" + (index + 1)] || false}
                            onChange={this.handleChange("panel2" + (index + 1))}
                          >
                            <ExpansionPanelSummary
                              className="expandIcon"
                              expandIcon={
                                this.state.expanded["panel2" + (index + 1)] ? (
                                  <RemoveSharpIcon />
                                ) : (
                                  <AddSharpIcon />
                                )
                              }
                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography component="h3">
                                {item.program_name}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography component="label">
                                Conducted By
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.conducted_by}
                              </Typography>
                              <Typography component="label">Year</Typography>
                              <Typography component="span" color="textPrimary">
                                {item.year}
                              </Typography>
                              <Typography component="label">
                                Duration
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.duration}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* )} */}
              {/* {employee.skills && employee.skills.length > 0 && ( */}
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <div className="empCard p-0">
                  <h2 className="xs-pl15 mB0">Skills</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={12}
                      className="empdetails empBox p-0 accordianContainer"
                    >
                      {employee.skills &&
                        employee.skills.map((item, index) => (
                          <ExpansionPanel
                            key={index}
                            expanded={expanded["panel3" + (index + 1)] || false}
                            onChange={this.handleChange("panel3" + (index + 1))}
                          >
                            <ExpansionPanelSummary
                              className="expandIcon"
                              expandIcon={
                                this.state.expanded["panel3" + (index + 1)] ? (
                                  <RemoveSharpIcon />
                                ) : (
                                  <AddSharpIcon />
                                )
                              }
                              aria-controls="panel3bh-content"
                              id="panel3bh-header"
                            >
                              <Typography component="h3">
                                {item.technology.name} - {item.skill.name}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography component="label">
                                Year of Experience
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.year_of_exp}
                              </Typography>
                              <Typography component="label">
                                Competency Level
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.competency_level}
                              </Typography>
                              <Typography component="label">
                                Last Used
                              </Typography>
                              <CustomDateFormat utc_date={item.last_used} />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* )} */}
              {/* {employee.jobHistories && employee.jobHistories.length > 0 && ( */}
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <div className="empCard">
                  <h2 className="xs-pl15 mB0">Job History</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={12}
                      className="empdetails empBox p-0 accordianContainer"
                    >
                      {employee.jobHistories &&
                        employee.jobHistories.map((item, index) => (
                          <ExpansionPanel
                            key={index}
                            expanded={expanded["panel4" + (index + 1)] || false}
                            onChange={this.handleChange("panel4" + (index + 1))}
                          >
                            <ExpansionPanelSummary
                              className="expandIcon"
                              expandIcon={
                                this.state.expanded["panel4" + (index + 1)] ? (
                                  <RemoveSharpIcon />
                                ) : (
                                  <AddSharpIcon />
                                )
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography component="h3">
                                {item.organization_name}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography component="label">
                                From Date
                              </Typography>
                              <CustomDateFormat utc_date={item.from_date} />
                              <Typography component="label">To Date</Typography>
                              <CustomDateFormat utc_date={item.to_date} />
                              <Typography component="label">
                                Last position
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.last_position}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* )} */}

              {/* {employee.certificates && employee.certificates.length > 0 && ( */}
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <div className="empCard">
                  <h2 className="xs-pl15 mB0">Certificates</h2>
                  <Grid container className="xs-pl15">
                    <Grid
                      item
                      xs={12}
                      className="empdetails empBox p-0 accordianContainer"
                    >
                      {employee.certificates &&
                        employee.certificates.map((item, index) => (
                          <ExpansionPanel
                            key={index}
                            expanded={
                              expanded["certifAccordian" + (index + 1)] || false
                            }
                            onChange={this.handleChange(
                              "certifAccordian" + (index + 1)
                            )}
                          >
                            <ExpansionPanelSummary
                              className="expandIcon"
                              expandIcon={
                                this.state.expanded[
                                  "certifAccordian" + (index + 1)
                                ] ? (
                                  <RemoveSharpIcon />
                                ) : (
                                  <AddSharpIcon />
                                )
                              }
                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography component="h3">
                                {item.certificate_name}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography component="label">Year</Typography>
                              <Typography component="span" color="textPrimary">
                                {item.year_of_certification}
                              </Typography>
                              <Typography component="label">
                                Institute
                              </Typography>
                              <Typography component="span" color="textPrimary">
                                {item.institute_name}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* )} */}
            </Grid>
          </div>
        </div>
      );
    }
  }
}
