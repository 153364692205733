import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isAuthorised, selfActionsAllowed } from "../utils/accessHelper";

// const isAuthenticated = true;
function ProtectedRoute(props) {
  // Role access allowed
  let isAllowed = false;

  if (!props.isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
    );

  let { permissions, selfActionPermission } = props;

  if (typeof permissions === "undefined") {
    isAllowed = true;
  } else if (Array.isArray(permissions) && permissions.length > 0) {
    let index = 0;
    while (isAllowed !== true && index < permissions.length) {
      isAllowed = isAuthorised(permissions[index]);
      console.log(isAllowed);
      index++;
    }
  } else {
    isAllowed = isAuthorised(permissions);
  }

  // checking self permissions
  if (selfActionPermission && !isAllowed) {
    let { profileId } = props.computedMatch.params;
    isAllowed = selfActionsAllowed(profileId);
  }

  // now check in access is allowed
  if (isAllowed) return <Route {...props} />;
  else return <Redirect to="/401" />;
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  permissionsAllowed: state.auth.user.permissions
});

// Proptypes
ProtectedRoute.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default connect(mapStateToProps, null)(ProtectedRoute);

ProtectedRoute.PropTypes = {
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selfActionPermission: PropTypes.bool
};
