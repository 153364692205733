import { SET_CURRENT_USER } from "./types";
// import Axios from "axios";
import { setAuthToken } from "../utils/tokenHelpers";

// export const authLogout = () => ({
//   type: AUTH_LOGOUT,
//   payload: {}
// });

export const authLogin = authData => dispatch => {
  // Axios.post(URL).then(res=>res).catch(err){}
  let { token, user } = authData;
  user.token = token;
  // // setting token to local storage
  localStorage.setItem("_token", token);
  //setting token to auth header
  setAuthToken(token);
  dispatch(setCurrentUser(user));
};

// action creators
export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    payload: user
  };
}

// logout user action creator
export const logoutUser = () => dispatch => {
  //remove token from localStorage
  localStorage.removeItem("_token");
  // remove auth header for future requests
  setAuthToken(false);
  // set current user to {} also isAuthenticated to false
  dispatch(setCurrentUser({}));
};
