import React from "react";
import { withFormik } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import avatar from "../../../assets/images/avatar.png";
import { Base_URL } from "../../../constants/index";
import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getIn, ErrorMessage } from "formik";

import clsx from "clsx";
// import { toFormData } from "../../../utils/formHelper";
import moment from "moment";

function personalForm(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    officeLocations = [],
    departments = [],
    designations = [],
    isSubmitting,
    users = [],
    isEditable,
    selfEditable,
    roles = [],
    locked,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xl={3} lg={3} md={3} xs={12} className="empPic">
        <input
          accept="image/*"
          id="contained-button-file"
          type="file"
          name="avatar"
          onChange={(event) => {
            setFieldValue("avatar", event.currentTarget.files[0]);
          }}
          disabled={!isEditable && !selfEditable}
          className="d-none"
        />
        <label htmlFor="contained-button-file">
          <span component="figure" className="avatar">
            <img
              alt="profile_pic"
              src={
                (values.avatar && URL.createObjectURL(values.avatar)) ||
                (values.user &&
                  values.user.avatar &&
                  `${Base_URL}${values.user.avatar}`) ||
                avatar
              }
              className="profilePic"
            />
          </span>
        </label>
      </Grid>
      <Grid item xl={9} lg={9} md={9} xs={12}>
        <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
          <Grid xs={12} className="formContainer">
            {values.employeeCode && (
              <div className="input-group">
                <label className="d-inline-block font14">Employee ID : </label>
                <input
                  type="text"
                  placeholder="Enter employee id"
                  name="employeeCode"
                  className={clsx(
                    "mL10 empLabel",
                    getIn(errors, "employeeCode") &&
                      getIn(touched, "employeeCode") &&
                      "error"
                  )}
                  value={values.employeeCode || ""}
                  disabled
                />
              </div>
            )}
          </Grid>
          <Grid
            container
            className="xs-pl15 pT15 pB15 box-border mB15"
            spacing={0}
          >
            <Grid item xs={6} md={4} lg={4} xl={4} className="formContainer">
              <div className="input-group">
                <label>Name* </label>
                <select
                  className="titleOption"
                  name="user.title"
                  {...getFieldProps("user.title")}
                  value={values.user && values.user.title}
                  disabled={!isEditable}
                >
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
                <input
                  type="text"
                  className={clsx(
                    "form-input",
                    getIn(errors, "user.name") && "error"
                  )}
                  name="user.name"
                  {...getFieldProps(`user.name`)}
                  value={(values.user && values.user.name) || ""}
                  placeholder="Enter full name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEditable}
                />
                <ErrorMessage
                  component="p"
                  name="user.name"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Designation </label>
                <select
                  name="designation"
                  {...getFieldProps("designation")}
                  value={(values.designation && values.designation) || ""}
                  className={clsx(
                    "form-select",
                    getIn(errors, "designation") &&
                      getIn(touched, "designation") &&
                      "error"
                  )}
                  disabled={!isEditable}
                >
                  <option value="">Select Designation</option>
                  {designations.map((designation, index) => (
                    <option key={`dept${index}`} value={designation._id}>
                      {designation.title}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  component="p"
                  name="designation"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
              <div className="input-group">
                <label>Department </label>
                <select
                  name="department"
                  {...getFieldProps("department")}
                  value={(values.department && values.department) || undefined}
                  className={clsx(
                    "form-select",
                    getIn(errors, "department") &&
                      getIn(touched, "department") &&
                      "error"
                  )}
                  disabled={!isEditable}
                >
                  <option value="">Select Department</option>
                  {departments.map((department, index) => (
                    <option key={`dept${index}`} value={department._id}>
                      {department.title}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  component="p"
                  name="department"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Reporting to </label>
                <select
                  name="reportingPerson"
                  {...getFieldProps("reportingPerson")}
                  value={values.reportingPerson}
                  className={clsx(
                    "form-select",
                    getIn(errors, "reportingPerson") &&
                      getIn(touched, "reportingPerson") &&
                      "error"
                  )}
                  disabled={!isEditable}
                >
                  <option value="">Select Reporting to</option>
                  {users.map((employee, index) => (
                    <option
                      key={`employee${index}`}
                      value={employee && employee.user && employee.user._id}
                    >
                      {employee.user && employee.user.name} (
                      {employee.employeeCode})
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  component="p"
                  name="reportingPerson"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4} className="formContainer">
              <Grid container spacing={1}>
                <Grid item xs={6} md={8} lg={8} xl={6}>
                  <div className="input-group">
                    <label className="mB15">Gender </label>
                    <RadioGroup aria-label="position" name="position" row>
                      <FormControlLabel
                        className="cssCheck mL-5"
                        control={<Radio color="primary" />}
                        label="Male"
                        {...getFieldProps(`gender`)}
                        name={`gender`}
                        labelPlacement="end"
                        value="Male"
                        checked={values.gender === "Male"}
                        disabled={!isEditable}
                      />
                      <FormControlLabel
                        className="cssCheck"
                        control={<Radio color="primary" />}
                        label="Female"
                        {...getFieldProps(`gender`)}
                        name={`gender`}
                        labelPlacement="end"
                        value="Female"
                        checked={values.gender === "Female"}
                        disabled={!isEditable}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={6} md={4} lg={4} xl={6}>
                  <div className="input-group">
                    <label>Blood Group </label>
                    <select
                      className="form-select"
                      name="bloodGroup"
                      {...getFieldProps("bloodGroup")}
                      value={values.bloodGroup || ""}
                      disabled={!isEditable && !selfEditable}
                    >
                      <option value="">Select</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                    <ErrorMessage
                      component="p"
                      name="bloodGroup"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Mobile Number* </label>
                <input
                  type="text"
                  placeholder="Enter mobile number"
                  name="contactNumber[0]"
                  {...getFieldProps("contactNumber[0]")}
                  value={
                    (values.contactNumber && values.contactNumber[0]) || ""
                  }
                  className={clsx(
                    "form-input",
                    getIn(errors, "contactNumber[0]") &&
                      getIn(touched, "contactNumber[0]") &&
                      "error"
                  )}
                  disabled={!isEditable && !selfEditable}
                />
                <ErrorMessage
                  component="p"
                  name="contactNumber"
                  className="error-text"
                />
              </div>
            </Grid>

            <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
              <div className="input-group">
                <label>Citizenship </label>
                <select
                  className="form-select"
                  name="citizenship"
                  {...getFieldProps("citizenship")}
                  value={values.citizenship || "Indian"}
                  disabled={!isEditable && !selfEditable}
                >
                  <option value="">Select</option>
                  <option value="Indian">Indian</option>
                  <option value="Other">Other</option>
                </select>
                <ErrorMessage
                  component="p"
                  name="citizenship"
                  className="error-text"
                />
              </div>
            </Grid>

            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Employment Status* </label>
                <select
                  className={clsx(
                    "form-select",
                    getIn(errors, "employementStatus") &&
                      getIn(touched, "employementStatus") &&
                      "error"
                  )}
                  name="employementStatus"
                  {...getFieldProps("employementStatus")}
                  value={values.employementStatus || ""}
                  disabled={!isEditable}
                >
                  <option value="">Select Status</option>
                  <option value="Regular">Regular</option>
                  <option value="Contract">Contract</option>
                  <option value="Consultant">Consultant</option>
                </select>
                <ErrorMessage
                  component="p"
                  name="employementStatus"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={4} lg={4} xl={4} className="formContainer">
              <div className="input-group">
                <label>Official Email* </label>
                <input
                  type="text"
                  placeholder="Enter email id"
                  {...getFieldProps("user.email")}
                  className={clsx(
                    "form-input",
                    getIn(errors, "user.email") &&
                      getIn(touched, "user.email") &&
                      "error"
                  )}
                  disabled={!isEditable}
                />
                <ErrorMessage
                  component="p"
                  name="user.email"
                  className="error-text"
                  value={
                    touched.personal &&
                    touched.personal.user.email &&
                    errors.personal &&
                    errors.personal.user.email
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Personal Email </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter email id"
                  name="personalEmail"
                  {...getFieldProps("personalEmail")}
                  value={values.personalEmail || ""}
                  disabled={!isEditable && !selfEditable}
                />
                <ErrorMessage
                  component="p"
                  name="personalEmail"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
              <div className="input-group">
                <label>Marital Status </label>
                <select
                  className="form-select"
                  name="maritalStatus"
                  {...getFieldProps("maritalStatus")}
                  value={values.maritalStatus}
                  disabled={!isEditable && !selfEditable}
                >
                  <option value="">Select</option>
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                </select>
                <ErrorMessage
                  component="p"
                  name="maritalStatus"
                  className="error-text"
                />
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} className="formContainer">
              <div className="input-group">
                <label>Resigned </label>
                <select
                  className={clsx(
                    "form-select",
                    getIn(errors, "resigned") &&
                      getIn(touched, "resigned") &&
                      "error"
                  )}
                  name="resigned"
                  {...getFieldProps("resigned")}
                  value={values.resigned ? "yes" : "no"}
                  disabled={!isEditable}
                >
                  <option value="">No</option>
                  <option value="yes">Yes</option>
                </select>
                <ErrorMessage
                  component="p"
                  name="resigned"
                  className="error-text"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <Grid
          container
          className="xs-pl15 pT15 pB15 box-border mB15"
          spacing={4}
        >
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Location </label>
              <select
                className="form-select"
                name="location"
                {...getFieldProps("location")}
                value={values.location || ""}
                disabled={!isEditable && !selfEditable}
              >
                <option value="">Select Location</option>
                {officeLocations.map((location, index) => (
                  <option key={`location${index}`} value={location._id}>
                    {location.title}
                  </option>
                ))}
              </select>
              <ErrorMessage
                component="p"
                name="location"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>PAN </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter PAN number"
                name="panNumber"
                {...getFieldProps("panNumber")}
                value={values.panNumber || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name="panNumber"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Aadhar </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter aadhar number"
                name="aadhar"
                {...getFieldProps("aadhar")}
                value={values.aadhar || ""}
                disabled={!isEditable && !locked}
              />
              <ErrorMessage
                component="p"
                name="aadhar"
                className="error-text"
              />
            </div>
          </Grid>

          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Father / Spouse Name* </label>
              <select
                name="relationPerson.relation"
                {...getFieldProps("relationPerson.relation")}
                value={
                  (values.relationPerson && values.relationPerson.relation) ||
                  ""
                }
                disabled={!isEditable}
                className="titleOption realtiveName"
              >
                <option>Father / Spouse Relation</option>
                <option value="Father">Father's Name</option>
                <option value="Spouse">Spouse Name</option>
                <option value="Mother">Mother's Name</option>
              </select>

              <input
                type="text"
                className="form-input"
                placeholder="Enter full name"
                name="relationPerson.name"
                {...getFieldProps("relationPerson.name")}
                value={
                  (values.relationPerson && values.relationPerson.name) || ""
                }
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name="relationPerson.relation"
                className="error-text"
              />
              <ErrorMessage
                component="p"
                name="relationPerson.name"
                className="error-text"
                value
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Emergency Contact Name </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter name"
                name="emergency[0].name"
                {...getFieldProps("emergency[0].name")}
                value={
                  (values.emergency &&
                    values.emergency[0] &&
                    values.emergency[0].name) ||
                  ""
                }
                disabled={!isEditable && !selfEditable}
              />

              <ErrorMessage
                component="p"
                name={`emergency[0].name`}
                className="error-text"
                value={
                  touched.emergency &&
                  touched.emergency[0] &&
                  touched.emergency[0].name &&
                  errors.emergency &&
                  errors.emergency[0] &&
                  errors.emergency[0].name
                }
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Emergency Contact Number</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter mobile number"
                name="emergency[0].contact"
                {...getFieldProps("emergency[0].contact")}
                value={
                  (values.emergency &&
                    values.emergency[0] &&
                    values.emergency[0].contact) ||
                  ""
                }
                disabled={!isEditable && !selfEditable}
              />
              <ErrorMessage
                component="p"
                name={`emergency[0].contact`}
                className="error-text"
                value={
                  touched.emergency &&
                  touched.emergency[0] &&
                  touched.emergency[0].contact &&
                  errors.emergency &&
                  errors.emergency[0] &&
                  errors.emergency[0].contact
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <Grid
          container
          className="xs-pl15 pT15 pB15 box-border mB15"
          spacing={4}
        >
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>DOB </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className="picker-input"
                  value={values.dateOfBirth || null}
                  name="dateOfBirth"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue(`dateOfBirth`, moment(e).toISOString());
                  }}
                  maxDate={new Date()}
                  disabled={!isEditable && !locked}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Birthday Wishes On </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className="picker-input"
                  value={values.realBirth || null}
                  name="realBirth"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue(`realBirth`, moment(e).toISOString());
                  }}
                  maxDate={new Date()}
                  disabled={!isEditable && !locked}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Date of Joining </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "dateOfJoining") &&
                      getIn(touched, "dateOfJoining") &&
                      "error"
                  )}
                  value={values.dateOfJoining || null}
                  name="dateOfJoining"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("dateOfJoining", moment(e).toISOString());
                  }}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={new Date()}
                  disabled={!isEditable}
                />
              </MuiPickersUtilsProvider>
              <ErrorMessage
                component="p"
                name="dateOfJoining"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Date of First Relevant Job </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "dateOfRelJob") &&
                      getIn(touched, "dateOfRelJob") &&
                      "error"
                  )}
                  value={values.dateOfRelJob || null}
                  name="dateOfRelJob"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("dateOfRelJob", moment(e).toISOString());
                  }}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={new Date()}
                  disabled={!isEditable && !locked}
                />
              </MuiPickersUtilsProvider>
              <ErrorMessage
                component="p"
                name="dateOfRelJob"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Date of Separation </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "dateOfLeaving") &&
                      getIn(touched, "dateOfLeaving") &&
                      "error"
                  )}
                  value={values.dateOfLeaving || null}
                  name="dateOfLeaving"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("dateOfLeaving", moment(e).toISOString());
                  }}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={!isEditable}
                />
              </MuiPickersUtilsProvider>
              <ErrorMessage
                component="p"
                name="dateOfLeaving"
                className="error-text"
              />
            </div>
          </Grid>

          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>EPF Status </label>
              <select
                className="form-select"
                name="epfStatus"
                {...getFieldProps("epfStatus")}
                value={values.epfStatus || ""}
                disabled={!isEditable && !locked}
              >
                <option value="">Select Status</option>
                <option value="Member">Member</option>
                <option value="Non Member">Non Member</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>UAN Number </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter UAN number"
                name="uan"
                {...getFieldProps("uan")}
                value={values.uan || ""}
                disabled={!isEditable && !locked}
              />
              <ErrorMessage component="p" name="uan" className="error-text" />
            </div>
          </Grid>

          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>ESI Status </label>
              <select
                className="form-select"
                name="esiStatus"
                {...getFieldProps("esiStatus")}
                value={values.esiStatus || ""}
                disabled={!isEditable && !locked}
              >
                <option value="">Select Status</option>
                <option value="Member">Member</option>
                <option value="Non Member">Non Member</option>
              </select>
            </div>
          </Grid>

          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Passport Number </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter passport number"
                name="passport"
                {...getFieldProps("passport")}
                value={values.passport || ""}
                disabled={!isEditable && !locked}
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Issue at </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter issue at"
                name="passportIssueAt"
                {...getFieldProps("passportIssueAt")}
                value={values.passportIssueAt || ""}
                disabled={!isEditable && !locked}
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Valid Upto </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "PassportvalidUpto") &&
                      getIn(touched, "PassportvalidUpto") &&
                      "error"
                  )}
                  value={values.PassportvalidUpto || null}
                  name="PassportvalidUpto"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("PassportvalidUpto", moment(e).toISOString());
                  }}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={!isEditable && !locked}
                />
              </MuiPickersUtilsProvider>
              <ErrorMessage
                component="p"
                name="PassportvalidUpto"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
              <div className="input-group">
                <label>Role </label>
                <select
                  className="form-select"
                  name="role"
                  {...getFieldProps("role")}
                  value={values.role}
                  disabled={!isEditable}
                >
                  <option>Select Role</option>
                  {roles.map((role) => (
                    <option value={role._id}>{role.name}</option>
                  ))}
                </select>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {(isEditable || selfEditable) && (
        <Grid item xs={12} md={12} lg={12} xl={12} className="formContainer">
          <div className="permissionBtn pL0">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="mL20"
              onClick={props.resetForm}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const formikPersonalForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      user: formData.user || { name: "", email: "" },
      employeeCode: formData.employeeCode,
      contactNumber: formData.contactNumber,
      employementStatus: formData.employementStatus,
      resigned: formData.resigned,
      department: formData.department ? formData.department._id : "",
      designation: formData.designation ? formData.designation._id : "",
      dateOfJoining: formData.dateOfJoining,
      dateOfRelJob: formData.dateOfRelJob,
      dateOfLeaving: formData.dateOfLeaving,
      PassportvalidUpto: formData.PassportvalidUpto,
      issueAt: formData.issueAt,
      extensionNumber: formData.extensionNumber,
      location: formData.location ? formData.location._id : "",
      citizenship: formData.citizenship ? formData.citizenship : "",
      reportingPerson: formData.reportingPerson
        ? formData.reportingPerson._id
        : "",
      userProfileId: formData._id,
      relationPerson: formData.relationPerson || { relation: "", name: "" },
      personalEmail: formData.personalEmail || "",
      emergency: formData.emergency || [{ name: "", contact: "" }],
      dateOfBirth: formData.dateOfBirth,
      realBirth: formData.realBirth,
      epfStatus: formData.epfStatus,
      //epfNumber: formData.epfNumber,
      panNumber: formData.panNumber,
      passport: formData.passport,
      passportIssueAt: formData.passportIssueAt,
      // PassportvalidUpto: formData.PassportvalidUpto,
      esiStatus: formData.esiStatus,
      // medicalStatus: formData.medicalStatus,
      uan: formData.uan || "",
      aadhar: formData.aadhar || "",
      bloodGroup: formData.bloodGroup,
      gender: formData.gender,
      maritalStatus: formData.maritalStatus,
      role: formData.role,
    };
    return initialValues;
  },

  //adding validation schema
  enableReinitialize: true,
  validationSchema: addEmployeeValidationSchema.personal,

  handleSubmit: (values, formikBag) => {
    let formData = formikBag.props.formData;
    values.employeeCode = formData.employeeCode;

    if (!formikBag.props.isEditable && formikBag.props.selfEditable) {
      let initialValues = {
        formType: "PersonalForm",
        user: formData.user || { name: "", email: "" },
        relationPerson: formData.relationPerson || [{ relation: "", name: "" }],
        employeeCode: formData.employeeCode,
        contactNumber: formData.contactNumber,
        employementStatus: formData.employementStatus,
        resigned: formData.resigned,
        department: formData.department ? formData.department._id : "",
        designation: formData.designation ? formData.designation._id : "",
        dateOfJoining: formData.dateOfJoining,
        dateOfRelJob: formData.dateOfRelJob,
        dateOfLeaving: formData.dateOfLeaving,

        issueAt: formData.issueAt,
        extensionNumber: formData.extensionNumber,
        //location: formData.location ? formData.location._id : "",
        reportingPerson: formData.reportingPerson
          ? formData.reportingPerson._id
          : "",
        userProfileId: formData._id,
        personalEmail: formData.personalEmail || "",
        emergency: formData.emergency,
        dateOfBirth: formData.dateOfBirth,
        realBirth: formData.realBirth,
        epfStatus: formData.epfStatus,
        //epfNumber: formData.epfNumber,
        panNumber: formData.panNumber,
        passport: formData.passport,
        passportIssueAt: formData.passportIssueAt,
        PassportvalidUpto: formData.PassportvalidUpto,
        esiStatus: formData.esiStatus,
        // medicalStatus: formData.medicalStatus,
        uan: formData.uan,
        aadhar: formData.aadhar || "",
        bloodGroup: formData.bloodGroup,
        gender: formData.gender,
        maritalStatus: formData.maritalStatus,
        role: formData.role,
      };
      initialValues.avatar = values.avatar;
      initialValues.bloodGroup = values.bloodGroup;
      initialValues.contactNumber = values.contactNumber;
      initialValues.personalEmail = values.personalEmail || "";
      initialValues.location = values.location ? values.location : "";
      initialValues.emergency = values.emergency;
      // Its for lock/unlock feature, It is coming from employee create with !
      if (formikBag.props.locked) {
        initialValues.citizenship = values.citizenship || "Indian";
        initialValues.maritalStatus = values.maritalStatus;
        initialValues.aadhar = values.aadhar || "";
        initialValues.dateOfBirth = values.dateOfBirth;
        initialValues.realBirth = values.realBirth;
        initialValues.dateOfRelJob = values.dateOfRelJob;
        initialValues.epfStatus = values.epfStatus;
        initialValues.uan = values.uan;
        initialValues.esiStatus = values.esiStatus;
        initialValues.passport = values.passport;
        initialValues.passportIssueAt = values.passportIssueAt;
        initialValues.PassportvalidUpto = values.PassportvalidUpto;
      }

      values = { ...initialValues };
    }

    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },

  displayName: "Personal",
})(personalForm);

export default formikPersonalForm;
