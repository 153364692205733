//  to convert object into array, inner data does not get modified
export function objectToArray(obj) {
  let arr = [];
  for (let key of Object.keys(obj)) {
    arr.push(obj[key]);
  }
  return arr;
}

export function dateSortAlgo(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}
