import React from "react";
import { withFormik } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import { Grid, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { ErrorMessage, FieldArray } from "formik";
// import clsx from "clsx";
// import { toFormData } from "../../../utils/formHelper";
import moment from "moment";

function jobhistoryForm(props) {
  const {
    values,

    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable,
    locked
  } = props;

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="jobHistories"
        render={arrayHelpers =>
          values.jobHistories && values.jobHistories.length > 0 ? (
            values.jobHistories.map((jobHistories, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  className="box-border p13 mB15"
                >
                  <Grid container className="xs-pl15 pT15 pB15 position-relative" spacing={4}>
                    <Grid item xs={3}>
                      <Grid container className="" spacing={4}>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>From Date* </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-dialog"
                                placeholder="Select date"
                                label={false}
                                autoOk={true}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                className="picker-input"
                                value={values.jobHistories[index].from_date || null}
                                name={`jobHistories[${index}].from_date`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    `jobHistories[${index}].from_date`,
                                    moment(e).toISOString()
                                  );
                                }}
                                disabled={!isEditable && !locked}
                              />
                            </MuiPickersUtilsProvider>
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].from_date`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>To Date </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-dialog"
                                placeholder="Select date"
                                label={false}
                                autoOk={true}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                className="picker-input"
                                value={values.jobHistories[index].to_date || null}
                                name={`jobHistories[${index}].to_date`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    `jobHistories[${index}].to_date`,
                                    moment(e).toISOString()
                                  );
                                }}
                                disabled={!isEditable && !locked}
                              />
                            </MuiPickersUtilsProvider>
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].to_date`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4}>
                      <Grid container className="" spacing={4}>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>Organization Name* </label>
                            <input
                              type="text"
                              className="form-input"
                              placeholder="Enter organization name"
                              name={`jobHistories[${index}].organization_name`}
                              {...getFieldProps(
                                `jobHistories[${index}].organization_name`
                              )}
                              value={
                                values.jobHistories[index].organization_name ||
                                ""
                              }
                              disabled={!isEditable && !locked}
                            />
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].organization_name`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>Last Position* </label>
                            <input
                              type="text"
                              className="form-input"
                              placeholder="Enter last position"
                              name={`jobHistories[${index}].last_position`}
                              {...getFieldProps(
                                `jobHistories[${index}].last_position`
                              )}
                              value={
                                values.jobHistories[index].last_position || ""
                              }
                              disabled={!isEditable && !locked}
                            />
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].last_position`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          xl={4}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>HR Name </label>
                            <input
                              type="text"
                              className="form-input"
                              placeholder="Enter HR Name"
                              name={`jobHistories[${index}].HRName`}
                              {...getFieldProps(
                                `jobHistories[${index}].HRName`
                              )}
                              value={
                                values.jobHistories[index].HRName || ""
                              }
                              disabled={!isEditable && !locked}
                            />
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].HRName`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          xl={4}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>HR Contact </label>
                            <input
                              type="text"
                              className="form-input"
                              placeholder="Enter landline no."
                              name={`jobHistories[${index}].HRContact`}
                              {...getFieldProps(
                                `jobHistories[${index}].HRContact`
                              )}
                              value={
                                values.jobHistories[index].HRContact || ""
                              }
                              disabled={!isEditable && !locked}
                            />
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].HRContact`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={4}
                          xl={4}
                          className="formContainer"
                        >
                          <div className="input-group">
                            <label>HR Email </label>
                            <input
                              type="text"
                              className="form-input"
                              placeholder="Enter HR Email"
                              name={`jobHistories[${index}].HREmail`}
                              {...getFieldProps(
                                `jobHistories[${index}].HREmail`
                              )}
                              value={
                                values.jobHistories[index].HREmail || ""
                              }
                              disabled={!isEditable && !locked}
                            />
                            <ErrorMessage
                              component="p"
                              name={`jobHistories[${index}].HREmail`}
                              className="error-text"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                 
                    <div className="input-group btnConatinerR floatL jobHistoryBtn">
                        <label>&nbsp;</label>
                        <Button
                          className="removeBtn mT8"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          disabled={!isEditable && !locked}
                        >
                          -
                        </Button>
                        {index === values.jobHistories.length - 1 ? (
                          <Button
                            className="addBtn mT8 mL10"
                            onClick={() =>
                              arrayHelpers.insert(values.jobHistories.length, {
                                from_date: "",
                                to_date: "",
                                organization_name: "",
                                last_position: "",
                                HRName:"",
                                HRContact:"",
                                HREmail:""
                              })
                            }
                            disabled={!isEditable && !locked}
                          >
                            +
                          </Button>
                        ) : null}
                      </div>
                   
                  </Grid>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                arrayHelpers.push({
                  from_date: "",
                  to_date: "",
                  organization_name: "",
                  last_position: "",
                  HRName:"",
                  HRContact:"",
                  HREmail:""
                })
              }
              disabled={!isEditable && !locked}
              className="m-auto"
            >
              {/* show this when user has removed all friends from the list */}+
              Add New Job History
            </Button>
          )
        }
      />
      {(isEditable || selfEditable) && <React.Fragment></React.Fragment>}
      <Grid item xs={12} md={12} lg={12} xl={12} className="">
        <div className="permissionBtn pL0">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="mL20"
            onClick={props.resetForm}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

const formikjobhistoryForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      jobHistories: formData.jobHistories || []
    };
    return initialValues;
  },
  enableReinitialize: true,

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.jobHistories,

  handleSubmit: (values, formikBag) => {
    values.jobHistories = values.jobHistories || null;
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },
  displayName: "Job History"
})(jobhistoryForm);

export default formikjobhistoryForm;
