import React, { Component } from "react";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  // ButtonBase,
  Button,
  Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  DialogActions
} from "@material-ui/core";

import CustomBreadcrumb from "../../components/breadcrumb";
import { Link } from "react-router-dom";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Person from "@material-ui/icons/Person";
import { isAuthorised } from "../../utils/accessHelper";

import Axios from "axios";

export default class Roles extends Component {
  state = {
    open: false,
    roles: [],
    formData: {
      name: "",
      cloneId: null
    },
    error: null
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  submitForm = () => {
    this.setState({ error: null });
    let { name, cloneId } = this.state.formData;
    Axios.post("/roles/add", { name, cloneId })
      .then(response => {
        let { data } = response;
        let newRole = data.data;
        let roles = [...this.state.roles];
        roles.push(newRole);

        this.setState({ open: false, roles: roles });
      })
      .catch(err => {
        let error = err.response.data;
        this.setState({ error });
      });
  };

  // count active permissions;

  activePermissionCount = (permissions = []) => {
    let permissionCount = 0;
    permissions.forEach(element => {
      Object.values(element.actions).forEach(action => {
        if (action === true) permissionCount++;
      });
    });

    return permissionCount;
  };
  // lifecycle events
  componentDidMount() {
    // get all roles
    Axios.get("/roles/active-with-stats")
      .then(({ data }) => {
        this.setState({ roles: data });
      })
      .catch(err => console.log(err));
  }

  _renderRoles = (roles = []) => {
    console.log(roles);
    return roles.map((role, index) => {
      // let assignedPermissionCount = 0;

      return (
        <Grid
          item
          xl={2}
          lg={3}
          md={4}
          xs={12}
          className=""
          key={`roleKey${index}`}
        >
          <Card className="cardShadow">
            <Link
              className="cardBlock"
              to={`/roles/${role._id}`}
              style={{ textDecoration: "none" }}
            >
              <CardContent>
                <Typography variant="h4" component="h3" className="noBdr">
                  {role.name}
                </Typography>
                <div className="roleInfo">
                  <Typography color="textSecondary">
                    Permissions
                    <br />
                    <span className="permIcon">
                      {this.activePermissionCount(role.permissions)}
                      <VerifiedUser />
                    </span>
                  </Typography>
                  <Typography color="textSecondary">
                    Users
                    <br />
                    <span className="permIcon userIcon">
                      {role.totalUsers || 0}
                      <Person />
                    </span>
                  </Typography>
                </div>
              </CardContent>
            </Link>
          </Card>
        </Grid>
      );
    });
  };

  render() {
    let { roles, error } = this.state;
    return (
      <div style={{ maxWidth: "100%" }} className="tableTitle">
        <h1>Roles</h1>

        <div className="oflowH mB15">
          <CustomBreadcrumb
            data={[
              { heading: "Dashboard", link: "/" },
              { heading: "Roles and Permissions", link: "/roles" }
            ]}
          />

          <div className="btnConatinerR">
            {isAuthorised("role_permissions.add") && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleClickOpen}
              >
                Add Role
              </Button>
            )}
            <Dialog open={this.state.open} onClose={this.handleClose}>
              <div className="roleModal">
                <h3>Add New Role</h3>
                <div>
                  {error && error.message && (
                    <p className="error">{error && error.message}</p>
                  )}
                  <input
                    type="text"
                    placeholder="Role Name"
                    // value={formData.name}
                    onChange={e => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          name: e.target.value
                        }
                      });
                    }}
                    className="form-input"
                  />
                </div>
                <div className="">
                  <select
                    className="form-select"
                    onChange={e => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          cloneId: e.target.value
                        }
                      });
                    }}
                  >
                    <option value={null}>Select</option>
                    {roles.map((role, index) => (
                      <option value={role._id} key={`option${index}`}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                  <p className="notes">
                    <strong>Note:</strong> Select another role to clone
                    permissions
                  </p>
                </div>
              </div>

              <DialogActions className="p-0 pT15">
                <Button
                  onClick={this.submitForm}
                  variant="contained"
                  color="primary"
                  className=""
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Grid container spacing={2}>
          {this._renderRoles(this.state.roles)}
        </Grid>
      </div>
    );
  }
}
