import React from "react";
import MaterialTable from "material-table";
import CustomBreadcrumb from "../../components/breadcrumb";
import axios from "axios";

import CustomDateFormat from "../../components/DateFormat";

import { Typography, Button, Grid, Paper } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import TotalEmployee from "./../../assets/images/icon-02.svg";
import AvgYear from "./../../assets/images/icon-04.svg";
import AvgAge from "./../../assets/images/icon-03.svg";
import AddedIcon from "./../../assets/images/icon-01.svg";
import "./EmployeeList.scss";
import { isAuthorised } from "../../utils/accessHelper";
import { dateSortAlgo } from "../../utils/manipulate";

class EmployeeListingComponent extends React.Component {
  state = {
    isLoading: true,
    employees: [],
    employeeStats: [],
    tableprops: {},
    currentPage: 0,
    rowPerpage: 20
  };
  index = 1;
  async componentDidMount() {
    try {
      let response = await axios.get("/users/view");
      let statsData = await axios.get("/stats/employees");
      this.setState({
        employees: response.data,
        employeeStats: statsData.data,
        isLoading: false
      });
    } catch (err) {
      //console.log(err);
    }

    this.setState({ isLoaded: true });
  }

  render() {
    let { isLoading, employees, isLoaded } = this.state;
    // let { history } = this.props;
    if (!isLoaded) {
      return (
        <React.Fragment>
          <div className="loader"></div>
          <div className="loaderLogo"></div>
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ maxWidth: "100%" }} className="tableTitle">
          <h1>Employees</h1>

          <div className="oflowH mB15">
            <CustomBreadcrumb
              data={[
                { heading: "Dashboard", link: "/" },
                { heading: "Employees", link: "/employees" }
              ]}
            />
            <div className="btnConatinerR">
              {isAuthorised("employee_management.add") ? (
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to="/employees/add"
                >
                  + Add Employee
                </Button>
              ) : null}

              {/* <Button variant="contained" color="primary">
              + Add Employee
            </Button> */}
            </div>
          </div>
          <Grid container spacing={2} className="mB15">
            <Grid item xs={3}>
              <Paper className="employeeDetailsCard">
                <Typography component="div">
                  <Typography variant="h6" component="p">
                    Total Employees
                  </Typography>
                  <Typography component="h4">
                    {this.state.employees.length}
                  </Typography>
                </Typography>
                <Typography component="div" className="cardIcon">
                  <img src={TotalEmployee} alt="Employee Icon" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className="employeeDetailsCard">
                <Typography component="div">
                  <Typography variant="h6" component="p">
                    Average Experience
                  </Typography>
                  <Typography component="h4" className="experianceTab">
                    {Math.round(
                      this.state.employeeStats.averageYearsInCompany * 10
                    ) / 10} (Yrs)
                    <span>At Netprophets </span>
                  </Typography>
                  {/* <Typography component="h4" className="experianceTab">
                    NA<span>Total Experience</span>
                  </Typography> */}
                </Typography>
                <Typography component="div" className="cardIcon">
                  <img src={AvgYear} alt="Calendar Icon" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className="employeeDetailsCard">
                <Typography component="div">
                  <Typography variant="h6" component="p">
                    Average Age
                  </Typography>
                  <Typography component="h4">
                    {Math.round(this.state.employeeStats.averageAge * 10) / 10} (Yrs)
                  </Typography>
                </Typography>
                <Typography component="div" className="cardIcon">
                  <img src={AvgAge} alt="Avg Age Icon" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className="employeeDetailsCard">
                <Typography component="div">
                  <Typography variant="h6" component="p">
                    YTD Additions/Separations
                  </Typography>
                  <Typography component="h4">
                    {Math.round(this.state.employeeStats.joinedToDate)}/
                    {Math.round(this.state.employeeStats.resignedToDate)}
                  </Typography>
                </Typography>
                <Typography component="div" className="cardIcon pT5">
                  <img src={AddedIcon} alt="Added" />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <MaterialTable
            localization={{
              pagination: {
                labelRowsSelect: " "
              }
            }}
            options={{
              actionsColumnIndex: 9,
              pageSize: this.state.rowPerpage,
              pageSizeOptions: (() => {
                let options = [10, 20, 50, 100].filter(
                  option => this.state.employees.length > option
                );
                return options;
              })(),
              exportButton: true,
              searchFieldStyle: {
                border: "1px solid #ced4da",
                borderRadius: "5px",
                color: "#7c7c7c",
                padding: "0 5px 0 10px",
                width: "220px"
              }
            }}
            onChangeRowsPerPage={data => {
              this.setState({ rowPerpage: data });
              this.index = 1;
            }}
            onChangePage={(page, row) => {
              this.setState({ currentPage: page }, () => {
                this.index = page * this.state.rowPerpage + 1;
              });
              // console.log(page * this.state.rowPerpage);
            }}
            onOrderChange={() => {
              this.setState({ currentPage: 0 });
              this.index = 1;
            }}
            isLoading={isLoading}
            actions={(() => {
              let actionsAllowed = [];
              
              if (isAuthorised("employee_management.view"))
                actionsAllowed.push({
                  icon: "visibility",
                  tooltip: "View",
                  onClick: (event, rowData) => {
                    this.props.history.push(
                      "/employees/" + rowData._id + "/view"
                    );
                  }
                });

              if (isAuthorised("employee_management.edit"))
                actionsAllowed.push({
                  icon: "edit",
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    this.props.history.push(
                      "/employees/" + rowData._id + "/edit"
                    );
                  }
                });

              return actionsAllowed.length > 0 ? actionsAllowed : null;
            })()}
            columns={[
              {
                title: "",
                field: "tableData.id",
                sorting: false,
                //dataKey:"serial",
                render: rowData => {
                  if (
                    this.state.employees.length < this.state.rowPerpage &&
                    this.index > this.state.employees.length
                  ) {
                    this.index = 1;
                  }
                  if (
                    this.index >
                    this.state.rowPerpage * this.state.currentPage +
                      this.state.rowPerpage
                  ) {
                    this.index =
                      this.state.rowPerpage * this.state.currentPage + 1;
                  }
                  return this.index++;
                },

                cellStyle: {
                  width: "50px"
                }
              }, //tableData.id
              {
                title: "Employee ID",
                field: "employeeCode",
                defaultSort: "asc",
                //sorting: false,
                cellStyle: {
                  width: "150px"
                }
              },
              { title: "Name", field: "user.name" },
              //{ title: "Gender", field: "gender",sorting: false },
              { title: "Designation", field: "designation.title" },
              { title: "Department", field: "department.title" },
              { title: "Email", field: "user.email", sorting: false },
              {
                title: "Date of Joining",
                field: "ModifyDOJ",
                sorting: true,
                cellStyle: {
                  width: "160px"
                },

                customSort: (a, b) => {
                  return dateSortAlgo(a.dateOfJoining, b.dateOfJoining);
                },
                render: rowData =>
                  rowData.dateOfJoining && (
                    <CustomDateFormat utc_date={rowData.dateOfJoining} />
                  )
              },
              {
                title: "Reporting To",
                field: "reportingPerson.name",
                sorting: false
              }
            ]}
            data={employees}
            components={
              {
                // OverlayLoading: props => <LinearProgress />
              }
            }
            title={null}
          />
        </div>
      );
    }
  }
}

export default EmployeeListingComponent;
