import React from "react";
// import PropTypes from "prop-types";
import { withFormik } from "formik";
import {
  Grid,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  Link,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ErrorMessage, FieldArray } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import Axios from "axios";
import { Base_URL } from "../../../constants";
import { Link as RouterLink } from "react-router-dom";

const removeFileName = (key) => {
  document.getElementById("file" + key).innerHTML = "";
};

const documentForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable,
    documentCategory,
    uploadedDocuments,
    deleteDocumentHandler
  } = props;
  // handle file input function

  const handleFileChange = (event, key) => {
    setFieldValue(event.currentTarget.name, event.currentTarget.files[0]);
    document.getElementById("file" + key).innerHTML =
      event.currentTarget.files[0].name;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <Grid container className="xs-pl15  pB15" spacing={4}>
          <Grid item xs={6} md={5} lg={5} xl={5}>
            <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
              <Grid item xs={12} md={5} lg={5} xl={5} className="formContainer">
                <div className="input-group">
                  <label>Title* </label>
                  <input
                    type="text"
                    placeholder="Enter Document Name"
                    className="form-input"
                    name="documents[0].documentTitle"
                    {...getFieldProps(`documents[0].documentTitle`)}
                    value={values.documents[0].documentTitle}
                    disabled={!isEditable && !selfEditable}
                  />
                  <ErrorMessage
                    component="span"
                    name={`documents[0].documentTitle`}
                    className="error-text"
                    value={
                      touched.documents &&
                      touched.documents[0] &&
                      touched.documents[0].documentTitle &&
                      errors.documents &&
                      errors.documents[0] &&
                      errors.documents[0].documentTitle
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={7} lg={7} xl={7} className="formContainer">
                <div className="input-group input-file">
                  <label>Document* </label>
                  <span>
                    <input
                      id={"outlined-button-file"}
                      type="file"
                      name="documents[0].fileName"
                      onChange={(event) => handleFileChange(event, 0)}
                    />
                    <label
                      htmlFor={"outlined-button-file"}
                      className="uploadBtn d-inline-block v-top"
                    >
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                      <ErrorMessage
                        component="p"
                        name="documents[0].fileName"
                        className="error-text w160"
                      />
                    </label>
                    <span
                      className="d-inline-block v-top p13 font13 fileNameEllipsis"
                      id={"file0"}
                    ></span>
                  </span>
                </div>
              </Grid>
              {(isEditable || selfEditable) && (
                <Grid item xs={12} md={12} lg={12} xl={12} className="">
                  <div className="permissionBtn pL0 docUpload-btn">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      className="mL20"
                      onClick={() => {
                        props.resetForm();
                        removeFileName(0);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                <div className="document-view">
                  <div className="docview-title">
                    Uploaded Documents
                  </div>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>File Name</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {uploadedDocuments && uploadedDocuments.map((item, index) => {
                          return (
                            <TableRow key={`docItem${item._id}`}>
                              <TableCell> {++index} </TableCell>
                              <TableCell> {item.category.heading} </TableCell>
                              <TableCell> {item.category.name} </TableCell>
                              <TableCell> {item.documentTitle} </TableCell>
                              <TableCell> {item.originalName} </TableCell>
                              <TableCell>
                                <DeleteOutlinedIcon
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure to delete this?"
                                      )
                                    ) {
                                      deleteDocumentHandler(
                                        item._id,
                                        values.userProfileId
                                      );
                                    }
                                  }}
                                  className="document-dlt"
                                />
                                {(isEditable) && (
                                  <a href={Base_URL+item.file} target="_blank" download>Download</a>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={7} lg={7} xl={7}>
            <Grid container class="xs-pl15  pB15" spacing={4}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className="formContainer"
              >
                <div className="docHead">Mandatory Document Category</div>
                <div className="input-group">
                  <ErrorMessage
                    component="span"
                    name="documents[0].docCategory"
                    className="error-text"
                  />
                  {documentCategory.map((category, index) => {
                    return (
                      <div className="docCategory_box">
                        <label className="title">{category.parent}</label>
                        {category.child.map((item, index) => {
                          let disabledRadio =
                            item.profile &&
                            item.profile[0] &&
                            item.profile[0]._id
                              ? true
                              : false;
                          return (
                            <div className="docCategory--content">
                              <input
                                id={index}
                                type="radio"
                                name="documents[0].docCategory"
                                {...getFieldProps(`documents[0].docCategory`)}
                                value={item._id}
                                disabled={
                                  (!isEditable && !selfEditable) ||
                                  disabledRadio
                                }
                              />
                              <label for={index}>{item.name}</label>
                              <span
                                className={
                                  "upload-status " +
                                  (item.profile &&
                                  item.profile[0] &&
                                  item.profile[0]._id
                                    ? "active"
                                    : "inactive")
                                }
                              >
                                {item.profile &&
                                item.profile[0] &&
                                item.profile[0]._id
                                  ? "Submitted"
                                  : "Pending"}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const formikdocumenteForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      formType: "DocumentForm",
      //documents: formData.documents || [{documentTitle:"",docCategory:"",fileName:""}],
      documents: [{ documentTitle: "", docCategory: "", fileName: "" }]
    };
    return initialValues;
  },
  enableReinitialize: true,

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.udocuments,

  handleSubmit: (values, formikBag) => {
    values.documents = values.documents || null;

    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
      formikBag.resetForm();
      removeFileName(0);
    } else {
      console.log("Submit handler not provided");
    }
  },
  displayName: "Document",
})(documentForm);

export default formikdocumenteForm;
