import React from "react";
// import PropTypes from "prop-types";
import { withFormik } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
// import avatar from "../../../assets/images/avatar.png";
import { Grid, Button } from "@material-ui/core";

import { ErrorMessage, FieldArray } from "formik";
// import Axios from "axios";
// import clsx from "clsx";
// import moment from "moment";

function educationForm(props) {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable
  } = props;

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="educations"
        render={arrayHelpers =>
          values.educations && values.educations.length > 0 ? (
            values.educations.map((education, index) => (
              <React.Fragment key={index}>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                  <Grid container className="xs-pl15 pT15 pB15" spacing="4">
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Degree* </label>
                        <input
                          type="text"
                          placeholder="Enter degree"
                          className="form-input"
                          name={`educations[${index}].degree`}
                          {...getFieldProps(`educations[${index}].degree`)}
                          value={values.educations[index].degree || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`educations[${index}].degree`}
                          className="error-text"
                          value={
                            touched.educations &&
                            touched.educations[index] &&
                            touched.educations[index].degree &&
                            errors.educations &&
                            errors.educations[index] &&
                            errors.educations[index].degree
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Subjects* </label>
                        <input
                          type="text"
                          placeholder="Enter subjects"
                          className="form-input"
                          name={`educations[${index}].subject`}
                          {...getFieldProps(`educations[${index}].subject`)}
                          value={values.educations[index].subject || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`educations[${index}].subject`}
                          className="error-text"
                          value={
                            touched.educations &&
                            touched.educations[index] &&
                            touched.educations[index].subject &&
                            errors.educations &&
                            errors.educations[index] &&
                            errors.educations[index].subject
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Passing Year* </label>
                        <input
                          type="text"
                          placeholder="Enter passing year"
                          className="form-input"
                          name={`educations[${index}].passingYear`}
                          {...getFieldProps(`educations[${index}].passingYear`)}
                          value={values.educations[index].passingYear || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`educations[${index}].passingYear`}
                          className="error-text"
                          value={
                            touched.educations &&
                            touched.educations[index] &&
                            touched.educations[index].passingYear &&
                            errors.educations &&
                            errors.educations[index] &&
                            errors.educations[index].passingYear
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Institute* </label>
                        <input
                          type="text"
                          placeholder="Enter institute"
                          className="form-input"
                          name={`educations[${index}].institute`}
                          {...getFieldProps(`educations[${index}].institute`)}
                          value={values.educations[index].institute || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`educations[${index}].institute`}
                          className="error-text"
                          value={
                            touched.educations &&
                            touched.educations[index] &&
                            touched.educations[index].institute &&
                            errors.educations &&
                            errors.educations[index] &&
                            errors.educations[index].institute
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group btnConatinerR floatL">
                        <label>&nbsp;</label>
                        <Button
                          className="removeBtn mT8"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          disabled={!isEditable && !selfEditable}
                        >
                          -
                        </Button>

                        {index === values.educations.length - 1 ? (
                          <Button
                            className="addBtn mT8 mL10"
                            onClick={() =>
                              arrayHelpers.insert(values.educations.length, {
                                degree: "",
                                subject: "",
                                passingYear: "",
                                institute: ""
                              })
                            }
                            disabled={!isEditable && !selfEditable}
                          >
                            +
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={1} className="formContainer">
                  
                </Grid> */}
              </React.Fragment>
            ))
          ) : (
            <Button
              variant="outlined"
              className="m-auto"
              onClick={() => {
                arrayHelpers.push({
                  degree: "",
                  subject: "",
                  passingYear: "",
                  institute: ""
                });
              }}
              disabled={!isEditable && !selfEditable}
            >
              {/* show this when user has removed all friends from the list */}+
              Add New Education
            </Button>
          )
        }
      />
      {(isEditable || selfEditable) && (
        <Grid item xs={12} md={12} lg={12} xl={12} className="">
          <div className="permissionBtn pL0">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="mL20"
              onClick={props.resetForm}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const formikEducationForm = withFormik({
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      educations: formData.educations || []
    };
    return initialValues;
  },
  enableReinitialize: true,

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.educations,

  handleSubmit: (values, formikBag) => {
    values.educations = values.educations || null;
    console.log(JSON.stringify(values));
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },

  displayName: "Education"
})(educationForm);

export default formikEducationForm;
