import React from "react";
import { withFormik, ErrorMessage } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

function basicForm(props) {
  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    countries = [],
    presentAddressStates = [],
    presentAddressCities = [],
    permanentAddressStates = [],
    permanentAddressCities = [],
    apiCalled = {},
    getCities,
    getStates,
    isEditable,
    selfEditable,
    locked
  } = props;
  //console.log({ errors, values });
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
          <Grid item xs={12} className="formContainer">
            <h3>Present Address</h3>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Address Line 1 </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter address"
                name="presentAddress.address1"
                {...getFieldProps("presentAddress.address1")}
                value={
                  (values.presentAddress && values.presentAddress.address1) ||
                  ""
                }
                disabled={!isEditable && !selfEditable}
              />
              <ErrorMessage
                component="p"
                name="presentAddress.address1"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Address Line 2 </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter address"
                name="presentAddress.address2"
                {...getFieldProps("presentAddress.address2")}
                value={
                  (values.presentAddress && values.presentAddress.address2) ||
                  ""
                }
                disabled={!isEditable && !selfEditable}
              />
              <ErrorMessage
                component="p"
                name="presentAddress.address2"
                className="error-text"
              />
            </div>
          </Grid>
          {/* <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Mobile Number </label>
              <input
                type="number"
                className="form-input"
                placeholder="Enter mobile number"
                name="presentAddress.mobile"
                {...getFieldProps("presentAddress.mobile")}
                value={
                  (values.presentAddress && values.presentAddress.mobile) || ""
                }
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name="presentAddress.mobile"
                className="error-text"
              />
            </div>
          </Grid> */}
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Country </label>
              <select
                className="form-select presCountry"
                id="presCountry"
                {...getFieldProps("presentAddress.country")}
                value={
                  (values.presentAddress && values.presentAddress.country) || ""
                }
                onChange={e => {
                  if (getStates)
                    getStates(
                      e.target.value,
                      "presentAddressStates",
                      "presentAddressCities"
                    );
                  setFieldValue("presentAddress.country", e.target.value);
                }}
                disabled={!isEditable && !selfEditable}
              >
                {countries.map((country, index) => (
                  <option key={`country${index}`} value={country._id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>State </label>
              <select
                className="form-select"
                value={
                  (values.presentAddress && values.presentAddress.state) || ""
                }
                {...getFieldProps("presentAddress.state")}
                onChange={e => {
                  if (getCities)
                    getCities(e.target.value, "presentAddressCities");
                  setFieldValue("presentAddress.state", e.target.value);
                  setFieldValue("presentAddress.city", "");
                }}
                disabled={!isEditable && !selfEditable}
              >
                <option value="">Select</option>
                {presentAddressStates.map((state, index) => (
                  <option key={`state${index}`} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>City </label>
              <select
                className="form-select"
                disabled={(!isEditable && !selfEditable)}
                value={
                  (values.presentAddress && values.presentAddress.city) || ""
                }
                {...getFieldProps("presentAddress.city")}
              >
                <option value="">Select</option>
                {presentAddressCities.map((city, index) => (
                  <option key={`city${index}`} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Pincode </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter pincode number"
                name="presentAddress.pincode"
                {...getFieldProps("presentAddress.pincode")}
                value={
                  (values.presentAddress && values.presentAddress.pincode) || ""
                }
                disabled={!isEditable && !selfEditable}
              />
              <ErrorMessage
                component="p"
                name="presentAddress.pincode"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={12} className="formContainer">
            <h3>Permanent Address</h3>
          </Grid>
          <Grid item xs={12} className="formContainer">
            <FormControlLabel
              className="cssCheck mT"
              control={<Checkbox value="checkedA" />}
              label="Same as Present"
              onChange={e => {
                if (e.target.checked) {
                  if(values.presentAddress.state){
                    getCities(values.presentAddress.state, "permanentAddressCities");
                  }
                  setFieldValue(`permanentAddress`, values.presentAddress);
                } else {
                  setFieldValue(`permanentAddress`, {});
                }
              }}
              disabled={!isEditable && !locked}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Address Line 1 </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter address"
                name="permanentAddress.address1"
                {...getFieldProps("permanentAddress.address1")}
                value={
                  (values.permanentAddress &&
                    values.permanentAddress.address1) ||
                  ""
                }
                disabled={!isEditable && !locked}
              />
              <ErrorMessage
                component="p"
                name="permanentAddress.address1"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Address Line 2 </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter address"
                name="permanentAddress.address2"
                {...getFieldProps("permanentAddress.address2")}
                value={
                  (values.permanentAddress &&
                    values.permanentAddress.address2) ||
                  ""
                }
                disabled={!isEditable && !locked}
              />
              <ErrorMessage
                component="p"
                name="permanentAddress.address2"
                className="error-text"
              />
            </div>
          </Grid>
          {/* <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Mobile Number </label>
              <input
                type="number"
                className="form-input"
                placeholder="Enter mobile number"
                name="permanentAddress.mobile"
                {...getFieldProps("permanentAddress.mobile")}
                value={
                  (values.permanentAddress && values.permanentAddress.mobile) ||
                  ""
                }
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name="permanentAddress.mobile"
                className="error-text"
              />
            </div>
          </Grid> */}
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Country </label>
              <select
                className="form-select"
                {...getFieldProps("permanentAddress.country")}
                onChange={e => {
                  if (getStates)
                    getStates(
                      e.target.value,
                      "permanentAddressStates",
                      "permanentAddressCities"
                    );
                  setFieldValue(`permanentAddress.country`, e.target.value);
                }}
                value={
                  (values.permanentAddress &&
                    values.permanentAddress.country) ||
                  ""
                }
                disabled={!isEditable && !locked}
              >
                {countries.map((country, index) => (
                  <option key={`country1${index}`} value={country._id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>State </label>
              <select
                className="form-select"
                {...getFieldProps("permanentAddress.state")}
                onChange={e => {
                  if (getCities)
                    getCities(e.target.value, "permanentAddressCities");
                  setFieldValue(`permanentAddress.state`, e.target.value);
                  setFieldValue(`permanentAddress.city`, "");
                }}
                value={
                  (values.permanentAddress && values.permanentAddress.state) ||
                  ""
                }
                disabled={!isEditable && !locked}
              >
                <option value="">Select</option>
                {permanentAddressStates.map((state, index) => (
                  <option key={`state1${index}`} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>City </label>
              <select
                className="form-select"
                {...getFieldProps("permanentAddress.city")}
                disabled={(!isEditable  && !locked)}
                value={
                  (values.permanentAddress && values.permanentAddress.city) ||
                  ""
                }
              >
                <option value="">Select</option>
                {permanentAddressCities.map((city, index) => (
                  <option key={`city${index}`} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Pincode </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter pincode number"
                name="permanentAddress.pincode"
                {...getFieldProps("permanentAddress.pincode")}
                value={
                  (values.permanentAddress &&
                    values.permanentAddress.pincode) ||
                  ""
                }
                disabled={!isEditable  && !locked}
              />
              <ErrorMessage
                component="p"
                name="permanentAddress.pincode"
                className="error-text"
              />
            </div>
          </Grid>
          {(isEditable || selfEditable)  && (
            <Grid item xs={12} md={12} lg={12} xl={12} className="">
              <div className="permissionBtn pL0">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="mL20"
                  onClick={props.resetForm}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const formikBasicForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      presentAddress: formData.presentAddress || {},
      permanentAddress: formData.permanentAddress || {}
    };
    return initialValues;
  },
  enableReinitialize: true,
  //adding validation schema
  validationSchema: addEmployeeValidationSchema.basic,

  handleSubmit: (values, formikBag) => {

    let formData = formikBag.props.formData;
    if (!formikBag.props.isEditable && formikBag.props.selfEditable) { 

      let initialValues = {
        userProfileId: formData._id,
        permanentAddress: formData.permanentAddress || {}
      }
      if(formikBag.props.locked){
        initialValues.permanentAddress = values.permanentAddress || {};
      }
      initialValues.presentAddress = values.presentAddress || {};
      values = { ...initialValues };
    }

    if (Object.keys(values.permanentAddress).length === 0)
      values.permanentAddress = null;

    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },

  displayName: "Basic"
})(basicForm);

export default formikBasicForm;
