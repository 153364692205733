import { createStore, applyMiddleware } from "redux";
// import appliedMiddlewares from "./middlewares";
import "../index.css";
import thunk from "redux-thunk";

import reducer from "../reducers";

// for debugging redux-state in chrome
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  reducer,
  {},
  composeWithDevTools(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
);

export default store;
