import React, { useState, useEffect } from "react";
import CustomBreadcrumb from "../../components/breadcrumb";
import CustomDateFormat from "../../components/DateFormat";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tabs, Tab, Box, Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import axios from "axios";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${"index"}`,
  };
}

function Holidays(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  async function getHolidays() {
    try {
      let { data } = await axios.get("/api/holidays");
      setHolidays(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  let [holidays, setHolidays] = useState([]);

  useEffect(function () {
    getHolidays();
  }, []);

  let npHolidays = holidays.filter(
    (holiday) => holiday.location === "Noida" || holiday.location === ""
  );

  let regularHoliday = npHolidays.filter(
    (holiday) => holiday.rhStatus === false
  );

  let rhHolidays = npHolidays.filter((holiday) => holiday.rhStatus === true);

  //let fabHolidays = holidays.filter(holiday => holiday.location === "Fabindia");

  const classes = useStyles();
  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Holidays</h1>

      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Holidays", link: "/holidays" },
          ]}
        />
      </div>
      <div className="tabContainer">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          component="div"
          className="tabList tabPaneTable"
        >
          <Tab label="Netprophets" {...a11yProps(0)} />
          {/* <Tab label="Fabindia" {...a11yProps(1)} /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid item lg={6} md={6} xs={12}>
             <div className="holiday_table-gutterright">
             <h3>Office Holidays</h3>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left">Holiday</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Day</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {regularHoliday.map((holiday, index) => (
                      <TableRow key={holiday._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">
                          {holiday.description}
                        </TableCell>
                        <TableCell align="left">
                          <CustomDateFormat utc_date={holiday.holidayDate} />
                        </TableCell>
                        <TableCell align="left">
                          <CustomDateFormat
                            utc_date={holiday.holidayDate}
                            isDay={true}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
             </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className="holiday_table-gutterleft">
              <h3>Restricted Holidays</h3>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">Holiday</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Day</TableCell>
                  </TableRow>
                  <TableBody>
                    {rhHolidays.map((holiday, index) => (
                      <TableRow key={holiday._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">
                          {holiday.description}
                        </TableCell>
                        <TableCell align="left">
                          <CustomDateFormat utc_date={holiday.holidayDate} />
                        </TableCell>
                        <TableCell align="left">
                          <CustomDateFormat
                            utc_date={holiday.holidayDate}
                            isDay={true}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="holiday__notes">
                <h5>Kindly note:</h5>
                <ol>
                  <li>For “Restricted Holiday” each employee is entitled to take “One RH” in the calendar year.</li>
                  <li>All employees needs to apply for RH in advance for the smooth process. Please choose “RH” as leave type.</li>
                </ol>
              </div>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        {/* fab india holidays 
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Holiday</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Day</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fabHolidays.map((holiday, index) => (
                  <TableRow key={holiday._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">{holiday.description}</TableCell>
                    <TableCell align="left">
                      <CustomDateFormat utc_date={holiday.holidayDate} />
                    </TableCell>
                    <TableCell align="left">
                      <CustomDateFormat
                        utc_date={holiday.holidayDate}
                        isDay={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      
      */}
      </div>
    </div>
  );
}

export default Holidays;
