import { ADD_ERROR, REMOVE_ERROR } from "../actions/types";

const intialState = { message: null, status: null };

export default (state = intialState, action) => {
  switch (action.type) {
    case ADD_ERROR:
      const { message, status } = action.payload;
      return { message, state: status || null };

    case REMOVE_ERROR:
      return { message: null, status: null };

    default:
      return state;
  }
};
