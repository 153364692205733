import * as yup from "yup";

const applyLeaveValidationSchema = yup.object().shape({
  leaveType: yup.string().required(),
  rhDate: yup.date().when("leaveType", {
    is: "RH",
    then: yup.date().required("Please select a RH date!")
  }),
  cofDate: yup.date().when("leaveType", {
    is: "COF",
    then: yup.date().required("Please select a COF date!")
  }),
  client: yup.string().when("leaveType", {
    is: "OD",
    then: yup
      .string()
      .matches(/^[A-Za-z0-9 ]+$/, "Client should be Alphanumeric only.")
      .required()
  }),
  reason: yup
    .string()
    .matches(/^[A-Za-z0-9 ]+$/, "Reason should be Alphanumeric only.")
    .required()
});

export default applyLeaveValidationSchema;
