import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomBreadcrumb from "../../components/breadcrumb";
import { Typography, Box, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TableDateFilter from "../../components/TableDateFilter";
import Axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const LeaveRequest = () => {
  const [value, setValue] = useState(0);

  let [leavesData, setLeavesData] = useState([]);

  async function getLeavesData(filterParams = {}) {
    try {
      let { data } = await Axios.post("/api/leaves/actions", filterParams);

      let tableData = [];
      data.data.forEach(leave => {
        if (leave.status === "Pending") {
          tableData.push({
            empCode: leave.userProfile.employeeCode,
            empName: leave.userProfile.user.name,
            leaveType: leave.leaveType.title,
            from: `${moment(leave.startDate).format("ddd")} ${moment(
              leave.startDate
            ).format("DD-MMM-YYYY")}`,
            to: `${moment(leave.endDate).format("ddd")} ${moment(
              leave.endDate
            ).format("DD-MMM-YYYY")}`,
            leaveDay: leave.noOfDays,
            approveStatus: leave.status,
            reason: leave.reason,
            leaveId: leave._id
          });
        }
      });

      setLeavesData(tableData);
    } catch (err) {
      console.log(err);
    }
  }
  let [alert, setAlert] = useState({
    open: false,
    message: "",
    duration: 3000,
    color: ""
  });
  useEffect(function() {
    getLeavesData();
  }, []);

  let selectedRows = [];
  function getSelectedLeaves() {
    let selectedLeaves = [];
    selectedLeaves = selectedRows.map(row => row.leaveId);
    return selectedLeaves;
  }

  function leavesAction(action = "approve") {
    let leaveIds = getSelectedLeaves();
    let confirmDialog = "Do you want to approve the selected leaves?";
    let apriUrl = "api/leaves/action/approve";
    if (leaveIds.length <= 0) {
      return false;
    }
    let postData = {
      leaveIds: leaveIds
    };
    if (action === "reject") {
      confirmDialog = "Do you want to Reject the selected leaves?";
      apriUrl = "api/leaves/action/reject";
    }
    if (window.confirm(confirmDialog)) {
      Axios.post(apriUrl, postData).then(function(res) {
        setAlert({
          ...alert,
          message: res.data.message,
          color: "success",
          open: true
        });
        getLeavesData();
      });
    }
  }

  function handleLeaveRequestFilterSubmit(params) {
    let filterParams = params;
    getLeavesData(filterParams);
  }
  return (
    <div className="tableTitle" style={{ maxWidth: "100%" }}>
      <h1>Request</h1>
      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Leave", link: "/leave/dashboard" },
            { heading: "Approve", link: "/leave/approve" }
          ]}
        />
      </div>
      <div className="btnConatinerR btnR">
        <Button
          color="primary"
          variant="contained"
          onClick={() => leavesAction()}
        >
          APPROVE
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => leavesAction("reject")}
        >
          REJECT
        </Button>
      </div>
      <div className="tabContainer leaveReq">
        <TabPanel value={value} index={0} component="div" className="tabTable">
          <div className="leaveToolbar">
            <TableDateFilter
              handleFilterSubmit={handleLeaveRequestFilterSubmit}
            />
          </div>
          <MaterialTable
            columns={[
              { title: "Employee Code", field: "empCode" },
              {
                title: "Employee Name",
                field: "empName",
                cellStyle: {
                  "text-transform": "capitalize"
                }
              },
              { title: "Leave Type", field: "leaveType" },
              { title: "From", field: "from" },
              { title: "To", field: "to" },
              { title: "Leave Day", field: "leaveDay" },
              {
                title: "Approval Status",
                field: "approveStatus",
                cellStyle: {
                  color: "#4caf50"
                }
              },
              { title: "Reason", field: "reason" }
            ]}
            localization={{
              pagination: {
                labelRowsSelect: " "
              }
            }}
            options={{
              selection: true,
              selectionProps: rowData => ({
                disabled: rowData.approveStatus !== "Pending",
                color: "primary"
              }),
              searchFieldStyle: {
                border: "1px solid #ced4da",
                borderRadius: "5px",
                color: "#7c7c7c",
                padding: "0 5px 0 10px",
                width: "220px"
              }
            }}
            onSelectionChange={rows => {
              selectedRows = rows;
            }}
            data={leavesData}
            title=""
          />
        </TabPanel>
      </div>
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={e => {
          setAlert({
            ...alert,
            open: false,
            class: "",
            message: ""
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        message={alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={e => {
                setAlert({
                  ...alert,
                  open: false,
                  class: "",
                  message: ""
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </div>
  );
};

export default LeaveRequest;
