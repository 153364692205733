import React from "react";
import {
  Breadcrumbs,
  Link as MaterialLink,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";

const CustomBreadcrumb = props => {
  // or we can take props.sendNews
  const breadcrumbList = data => {
    var dataLength = data.length;
    return data.map((item, index) => {
      if (index === dataLength - 1) {
        return (
          <Typography key={`breadcrumb${index}`} color="textPrimary">
            {item.heading}
          </Typography>
        );
      } else {
        return (
          <MaterialLink
            key={`breadcrumb${index}`}
            color="inherit"
            component={Link}
            to={item.link ? item.link : "/"}
          >
            {item.heading}
          </MaterialLink>
        );
      }
    });
  };

  return (
    <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbList(props.data)}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumb;
