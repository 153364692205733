import React from "react";
import MaterialTable from "material-table";
import CustomBreadcrumb from "../../components/breadcrumb";
import axios from "axios";

import {
 Button
} from "@material-ui/core";

import "./../employee/EmployeeList.scss";

class PayStructure extends React.Component {
  state = {
    isLoading: true,
    paystructures: [],
    tableprops: {}
  };

  async componentDidMount() {
    try {
      let response = await axios.get("/payroll/paystructure/view");
      response.data.foreach((item, index) => {
        item.sn = ++index;
        //item.ModifyDOJ = <CustomDateFormat utc_date={item.dateOfJoining} />;
      });
      this.setState({ paystructures: response.data, isLoading: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    let { isLoading, paystructures } = this.state;
    this.serial = 0;
    return (
      <div style={{ maxWidth: "100%" }} className="tableTitle">
        <h1>Pay Structure Listing</h1>

        <div className="oflowH mB15">
          <CustomBreadcrumb
            data={[
              { heading: "Dashboard", link: "/" },
              { heading: "Payroll", link: "/payroll" },
              { heading: "Pay Structure Listing", link: "/payroll/paystructure" }
            ]}
          />
          <div className="btnConatinerR">

            <Button variant="contained" color="primary">
              Export
            </Button>
            <Button variant="contained" color="primary">
              Add Employee
            </Button>
          </div>
        </div>
        <MaterialTable
          options={{
            actionsColumnIndex: 9,
            pageSize: 50,
            exportButton: true
          }}
          isLoading={isLoading}

          actions={[
            {
              icon: "visibility",
              tooltip: "View",
              onClick: (event, rowData) => {
                // console.log("/employees/" + rowData._id + "/view");
                // window.open("/employees/" + rowData._id + "/view",'_newtab');
                this.props.history.push("/payroll/paystructure/" + rowData._id + "/view");
                // <Redirect to={"/employees/" + rowData._id + "/view"} />;
              }
            },
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => { }
            },
            {
              icon: "delete_outline",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                // Do save operation
              }
            }
          ]}
          columns={[
            { title: "S.N.", field: "sn" },   //tableData.id
            { title: "Emp-Code", field: "userp[0].employeeCode" },
            { title: "Name", field: "usera[0].name" },
            { title: "Annual CTC", field: "ps[0].annualCtc" },
            { title: "Monthly CTC", field: "ps[0].monthlyCtc" },
            { title: "Basic Salary", field: "ps[0].earnings.basicPay" },
            { title: "HRA", field: "ps[0].earnings.houseRentAllowance" },
            { title: "Income Tax", field: "ps[0].deductions.incomeTax" },
            { title: "Medical Insurance", field: "ps[0].deductions.medicalInsurance" }
          ]}
          data={paystructures}
          components={{
            // OverlayLoading: props => <LinearProgress />
          }}
          title={false}
          rowsPerPage={50}
        />
      </div>
    );
  }
}

export default PayStructure;