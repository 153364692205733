import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomBreadcrumb from "../../components/breadcrumb";
import Snackbar from "@material-ui/core/Snackbar";
// importing  sub forms
import PersonalForm from "./employeeForms/personalForm";
import BasicForm from "./employeeForms/basicForm";
import EducationForm from "./employeeForms/educationForm";
import MedicalForm from "./employeeForms/medicalForm";
import TrainingForm from "./employeeForms/trainingForm";
import SkillForm from "./employeeForms/skillForm";
import CertificateForm from "./employeeForms/certificateForm";
import JobhistoryForm from "./employeeForms/jobhistoryForm";
import DocumentForm from "./employeeForms/documentForm";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { isAuthorised, selfActionsAllowed } from "../../utils/accessHelper";

import Axios from "axios";
import { toFormData } from "../../utils/formHelper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper
//   }
// }));

class EmployeeCreate extends React.Component {
  state = {
    value: "one",
    selectedDate: new Date("2000-01-01"),
    prevStateEmployee: {},
    employee: {},
    departments: [],
    designations: [],
    presentAddressStates: [],
    technologySkillMap: [],
    presentAddressCities: [],
    permanentAddressStates: [],
    permanentAddressCities: [],
    countries: [],
    apiCalled: {
      presentAddressCities: false,
      presentAddressStates: false,
      permanentAddressCities: false,
      permanentAddressStates: false
    },
    loadedFromServer: false,
    roles: [],
    alert: {
      open: false,
      message: "",
      duration: 3000,
      color: ""
    }
  };

  handleAlertClose = () => {
    this.setState({
      alert: { ...this.state.alert, open: false, class: "", message: "" }
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  // formCancelHandler = () => {
  //   let employee = { ...this.state.employee };
  //   this.setState({ employee: { ...this.state.employee } });
  // };


  GetdocumentCategory = async (userProfileId) => {

  // All Document category with profile uploaded 
    let documentCategory = []; let  {data: documentCategoryResp }  = await Axios.get(`/masters/document-category/${userProfileId}`);
    let keyindex = 0; let mindex = -1; let heading = ''; let keyvalue;
     documentCategoryResp.data.map((item)=>{
                       if(heading != item.heading){
                         keyindex++; mindex++;
                         keyvalue = "key"+keyindex;
                         documentCategory.push(
                                 {
                                         parent : item.heading,
                                         child : [item]
                                 }
                               );
                       }else{
                         //console.log(modified_data[mindex][keyvalue].child);
                         documentCategory[mindex].child.push(item);
                       }
                       heading = item.heading;
             });
       // All Document category with profile uploaded 
      return documentCategory;
  }


  // form submit handler
  formSubmitHandler = (values, { setSubmitting }) => {
    let formData = new FormData();
    toFormData(formData, values);
    Axios.post(
      `/users/save?fileUploadEmployeeCode=${this.state.employee &&
        this.state.employee.employeeCode}`,
      formData
    )
      .then( async ({ data: userProfile }) => {
          //console.log(userProfile);
        
          // Set Document Category
        let documentCategory = await this.GetdocumentCategory(userProfile._id);

        this.setState({
          employee: userProfile,
          prevStateEmployee: userProfile,
          documentCategory:documentCategory,
          uploadedDocuments:userProfile.documents || [],
          alert: {
            ...this.state.alert,
            message: "Employee details saved successfully!",
            color: "success",
            open: true
          }
        });
        let { profileId } = this.props.match.params;
        if (!profileId) {
          this.props.history.push(`/employees/${userProfile._id}/edit`);
        }
      })
      .catch(err => {
        this.setState({
          alert: {
            ...this.state.alert,
            message: "Unable to save! Try again later.",
            color: "error",
            open: true
          }
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // lifecycle methods
  async componentDidMount() {
    let { params } = this.props.match;
    let { location } = this.props;
    let employee = null;
    let documentCategory = [];
    let locked = true;

    try {
      if (params.profileId) {
        let { data } = await Axios.get(`/users/view/${params.profileId}`);
        employee = data;
        locked = !employee.lock;
        if (employee.presentAddress && employee.presentAddress.country) {
          this.getStates(
            employee.presentAddress.country,
            "presentAddressStates"
          );
        }
        if (employee.presentAddress && employee.presentAddress.state) {
          this.getCities(employee.presentAddress.state, "presentAddressCities");
        }

        if (employee.permanentAddress && employee.permanentAddress.country) {
          this.getStates(
            employee.permanentAddress.country,
            "permanentAddressStates"
          );
        }
        if (employee.permanentAddress && employee.permanentAddress.state) {
          this.getCities(
            employee.permanentAddress.state,
            "permanentAddressCities"
          );
        }
        if (employee.skills && employee.skills[0] &&  employee.skills[0].skill) {
          this.getSkills(
            "technologySkillMap"
          );
        } 
          // Set Document Category
        documentCategory = await this.GetdocumentCategory(params.profileId);

      } else {
        // get new EmployeeCode from server
        
        //let { data: employeeCodeResponse } = await Axios.get(
          //"/users/view/generate/id"
       // );

        //employee = { employeeCode: employeeCodeResponse.data.newEmployeeCode };
        employee = { employeeCode: '' };
      }

      let [
        { data: designationData },
        { data: departmentsData },
        { data: officeLocations },
        { data: countiresData },
        { data: stateData },
        { data: technologyData },
        { data: UsersData },
        { data: roles }
      ] = await Promise.all([
        Axios.get("/masters/designations/options"),
        Axios.get("/masters/departments/options"),
        Axios.get("/masters/office_locations/options"),
        Axios.get("/masters/countries/india"),
        Axios.get(`/masters/states-by-country-id/${'5e15ba2b6d7e8568449276e0'}`),
        Axios.get("/masters/technology-areas"),
        Axios.get("/users/view"),
        Axios.get("/roles/active")
      ]);

      

      // console.log({ designationData });
      this.setState({
        prevStateEmployee: { ...employee } || {},
        employee: employee || {},
        loadedFromServer: true,
        designations: designationData.data,
        departments: departmentsData.data,
        officeLocations: officeLocations.data,
        users: UsersData,
        countries: countiresData.data,
        technologies:technologyData.data,
        presentAddressStates:stateData.data,
        permanentAddressStates:stateData.data,
        roles: roles,
        documentCategory:documentCategory ? documentCategory : null,
        uploadedDocuments:employee.documents || [],
        locked:locked
      });

      // console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  // get cities by state ID
  getCities = async (stateId, fieldName) => {
    this.setState({
      [fieldName]: [],
      apiCalled: { ...this.state.apiCalled, [fieldName]: false }
    });
    let { data: citiesData } = await Axios.get(
      `/masters/cities-by-state-id/${stateId}`
    );

    if (!stateId) return;
    this.setState({
      [fieldName]: citiesData.data,
      apiCalled: { ...this.state.apiCalled, [fieldName]: true }
    });
  };

  // get state by country ID
  getStates = async (countryId, fieldNameState, fieldNameCity) => {
    this.setState({
      [fieldNameState]: [],
      [fieldNameCity]: [],
      apiCalled: {
        ...this.state.apiCalled,
        state: false,
        [fieldNameState]: false,
        [fieldNameCity]: false
      }
    });

    if (!countryId) return;

    let { data: statesData } = await Axios.get(
      `/masters/states-by-country-id/${countryId}`
    );

    this.setState({
      [fieldNameState]: statesData.data,
      apiCalled: {
        ...this.state.apiCalled,
        [fieldNameState]: true
      }
    });
  };

  // get skill by technology ID
  getSkills = async (fieldNameSkill) => {
    this.setState({
      [fieldNameSkill]: []
    });

    let { data: skillData } = await Axios.get(
      `/masters/skills/`
    );

    this.setState({
      [fieldNameSkill]: skillData.data,
      apiCalled: {
        ...this.state.apiCalled,
        [fieldNameSkill]: true
      }
    });
  };

  // Delete Documents for user 
  deleteDocs = async (docId, profileId) => {

    let docData = { docId: docId, profileId: profileId };

    Axios.post("/users/delete/docs", docData)
      .then(async ({ data: result }) => {
        if (result.status == "success") {

          let { data } = await Axios.get(`/users/view/${profileId}`);
          let uploadedDocuments = data.documents;

          let documentCategory = await this.GetdocumentCategory(profileId);

              this.setState({
                documentCategory:documentCategory,
                uploadedDocuments:uploadedDocuments || [],
              });
        }
      });
  };

  render() {
    let { profileId } = this.props.match.params;
    let {
      value,
      employee,
      loadedFromServer,
      departments,
      designations,
      officeLocations,
      users,
      presentAddressStates,
      presentAddressCities,
      permanentAddressStates,
      permanentAddressCities,
      technologySkillMap,
      countries,
      technologies,
      apiCalled,
      alert,
      roles,
      documentCategory,
      uploadedDocuments,
      locked
    } = this.state;

    let isEditable =
      !profileId && isAuthorised("employee_management.add")
        ? true
        : profileId && isAuthorised("employee_management.edit")
        ? true
        : false;

    let selfEditable =
      profileId && selfActionsAllowed(profileId) ? true : false;

    if (!loadedFromServer)
      return (
        <React.Fragment>
          {" "}
          <div className="loader"></div>
          <div className="loaderLogo"></div>
        </React.Fragment>
      );

    return (
      <div className="tableTitle">
        <h1>{profileId ? "Edit" : "Add"} Employee</h1>
        <div className="oflowH mB15">
          <CustomBreadcrumb
            data={[
              { heading: "Dashboard", link: "/" },
              { heading: "Employees", link: "/employees" },
              {
                heading: profileId
                  ? "Edit " + (employee.user && employee.user.name) + " Profile"
                  : "Add Employee",
                link: "/employees"
              }
            ]}
          />
        </div>

        <div className="tabContainer">
          {profileId && (
            <React.Fragment>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="wrapped label tabs example"
                component="div"
                className="tabList"
              >
                <Tab
                  value="one"
                  label="Personal"
                  wrapped
                  {...a11yProps("one")}
                />
                <Tab
                  value="two"
                  label="Contact"
                  wrapped
                  {...a11yProps("two")}
                />
                <Tab value="three" label="Medical" {...a11yProps("three")} />
                <Tab value="four" label="Education" {...a11yProps("four")} />
                <Tab value="five" label="Training" {...a11yProps("five")} />
                <Tab value="six" label="Skills" {...a11yProps("six")} />
                <Tab
                  value="seven"
                  label="Certificates"
                  {...a11yProps("seven")}
                />
                <Tab
                  value="eight"
                  label="Job History"
                  {...a11yProps("eight")}
                />
                <Tab value="nine" label="Documents" {...a11yProps("nine")} />
              </Tabs>
            </React.Fragment>
          )}

          <TabPanel value={value} index="one">
            <PersonalForm
              formData={employee}
              departments={departments}
              designations={designations}
              users={users}
              officeLocations={officeLocations}
              submitHandler={this.formSubmitHandler}
              cancelHandler={this.formCancelHandler}
              isEditable={isEditable}
              selfEditable={selfEditable}
              roles={roles}
              locked={locked}
            />
          </TabPanel>
          <TabPanel value={value} index="two">
            <BasicForm
              formData={employee}
              presentAddressStates={presentAddressStates}
              presentAddressCities={presentAddressCities}
              permanentAddressStates={permanentAddressStates}
              permanentAddressCities={permanentAddressCities}
              countries={countries}
              apiCalled={apiCalled}
              getCities={this.getCities}
              getStates={this.getStates}
              submitHandler={this.formSubmitHandler}
              isEditable={isEditable}
              selfEditable={selfEditable}
              locked={locked}
            />
          </TabPanel>
          <TabPanel value={value} index="three">
            <MedicalForm
              formData={employee}
              submitHandler={this.formSubmitHandler}
              isEditable={isEditable}
              locked={locked}
            />
          </TabPanel>
          <TabPanel value={value} index="four">
            <EducationForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
          <TabPanel value={value} index="five">
            <TrainingForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
          <TabPanel value={value} index="six">
            <SkillForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              technologySkillMap={technologySkillMap}
              technologies={technologies}
              getSkills={this.getSkills}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
          <TabPanel value={value} index="seven">
            <CertificateForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
          <TabPanel value={value} index="eight">
            <JobhistoryForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              locked={locked}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
          <TabPanel value={value} index="nine">
            <DocumentForm
              selfEditable={selfEditable}
              isEditable={isEditable}
              documentCategory={documentCategory}
              uploadedDocuments={uploadedDocuments}
              deleteDocumentHandler={this.deleteDocs}
              formData={employee}
              submitHandler={this.formSubmitHandler}
            />
          </TabPanel>
        </div>
        <Snackbar
          open={alert.open}
          autoHideDuration={3000}
          onClose={this.handleAlertClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          message={alert.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleAlertClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        ></Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authuser: state.auth.user
});

export default EmployeeCreate;
