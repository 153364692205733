//  this files is responsible for managing access permissions

import store from "../store/index";

export function isAuthorised(moduleActionKey = null) {
  // return true;
  if (!moduleActionKey) return true;

  moduleActionKey = moduleActionKey.split(".");

  let storeData = store.getState();
  let permissions = storeData.auth.user.permissions;

  if (!permissions) return false;

  return permissions[moduleActionKey[0]] &&
    permissions[moduleActionKey[0]][moduleActionKey[1]]
    ? permissions[moduleActionKey[0]][moduleActionKey[1]]
    : false;
}

export function selfActionsAllowed(_id) {
  // return true;
  let storeData = store.getState();
  let user = storeData.auth.user;
  return _id === user._id || _id === user._profileId;
}

export function getObjectRefFromString(str) {
  return str.split(".").reduce((o, i) => o[i], str);
}
