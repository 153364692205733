import React from "react";
import MaterialTable from "material-table";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

export default function LeaveSummary(props) {
  const columns = [
    { title: "Employee Code", field: "empCode" },
    { title: "Employee Name", field: "empName" },
    { title: "EL Total", field: "elTotal" },
    { title: "EL Taken", field: "elTaken" },
    { title: "EL Balance", field: "elBalance" },
    { title: "RH Total", field: "rhTotal" },
    { title: "RH Taken", field: "rhTaken" },
    { title: "RH Balance", field: "rhBalance" },
    { title: "COF Total", field: "cofTotal" },
    { title: "COF Taken", field: "cofTaken" },
    { title: "COF Balance", field: "cofBalance" }
  ];

  const data = [
    {
      empCode: "123",
      empName: "Lalit",
      elTotal: "3",
      elTaken: "2",
      elBalance: "1",
      rhTotal: "1",
      rhTaken: "1",
      rhBalance: "0",
      cofTotal: "2",
      cofTaken: "1",
      cofBalance: "1"
    },
    {
      empCode: "123",
      empName: "Lalit",
      elTotal: "3",
      elTaken: "2",
      elBalance: "1",
      rhTotal: "1",
      rhTaken: "1",
      rhBalance: "0",
      cofTotal: "2",
      cofTaken: "1",
      cofBalance: "1"
    },
    {
      empCode: "123",
      empName: "Lalit",
      elTotal: "3",
      elTaken: "2",
      elBalance: "1",
      rhTotal: "1",
      rhTaken: "1",
      rhBalance: "0",
      cofTotal: "2",
      cofTaken: "1",
      cofBalance: "1"
    }
  ];
  return (
    <MaterialTable
      columns={columns}
      data={data}
      components={{
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Employee Code</TableCell>
                <TableCell rowSpan={2}>Employee Name</TableCell>
                <TableCell colSpan={3} align="center">
                  EL
                </TableCell>
                <TableCell colSpan={3} align="center">
                  RH
                </TableCell>
                <TableCell colSpan={3} align="center">
                  COF
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Taken</TableCell>
                <TableCell align="center">Balance</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Taken</TableCell>
                <TableCell align="center">Balance</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Taken</TableCell>
                <TableCell align="center">Balance</TableCell>
              </TableRow>
            </TableHead>
          );
        },
        Row: ({ data }) => {
          return (
            <TableRow>
              <TableCell>{data.empCode}</TableCell>
              <TableCell>{data.empName}</TableCell>
              <TableCell align="center">{data.elTotal}</TableCell>
              <TableCell align="center">{data.elTaken}</TableCell>
              <TableCell align="center">{data.elBalance}</TableCell>
              <TableCell align="center">{data.rhTotal}</TableCell>
              <TableCell align="center">{data.rhTaken}</TableCell>
              <TableCell align="center">{data.rhBalance}</TableCell>
              <TableCell align="center">{data.cofTotal}</TableCell>
              <TableCell align="center">{data.cofTaken}</TableCell>
              <TableCell align="center">{data.cofBalance}</TableCell>
            </TableRow>
          );
        }
      }}
      options={{
        searchFieldStyle: {
          border: "1px solid #ced4da",
          borderRadius: "5px",
          color: "#7c7c7c",
          padding: "0 5px 0 10px",
          width: "220px"
        }
      }}
      title=""
    />
  );
}
