import React, { Component } from "react";
import CustomBreadcrumb from "../../components/breadcrumb";
import { Autocomplete } from "@material-ui/lab";
import "./Roles.scss";
import CloseIcon from "@material-ui/icons/Close";
import { isAuthorised } from "../../utils/accessHelper";

import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControlLabel,
  Checkbox,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  Typography,
  // ExpansionPanelDetails,
  IconButton,
  Dialog,
  DialogActions,
  TextField
} from "@material-ui/core";
import { Base_URL } from "../../constants";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Axios from "axios";
// import LalitImg from "./../../../src/assets/images/lallit.png";
import SearchIcon from "@material-ui/icons/Search";
import avatarPlaceholder from "./../../assets/images/avatarPic.png";
import Snackbar from "@material-ui/core/Snackbar";

// import { Redirect } from "react-router-dom";
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 }
];

export default class RolePermission extends Component {
  state = {
    expanded: false,
    defaultPermissions: [],
    rolePermissions: [],
    originalRolePermissions: [],
    role: {},
    users: [],
    alert: {
      open: false,
      message: "",
      duration: 3000,
      color: ""
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen1 = () => {
    this.setState({ open1: true });
  };
  handleClose1 = () => {
    this.setState({ open1: false });
  };

  handleAlertClose = () => {
    this.setState({
      alert: { ...this.state.alert, open: false, class: "", message: "" }
    });
  };

  _resetForm = () => {
    this.setState({
      rolePermissions: JSON.parse(
        JSON.stringify(this.state.originalRolePermissions)
      )
    });
  };

  handleChange = panel => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  _savePermissions = () => {
    let { role, rolePermissions } = this.state;

    Axios.put(`/roles/${role._id}`, rolePermissions)
      .then(role => {
        this.setState({
          alert: {
            ...this.state.alert,
            message: role.data.message,
            color: "success",
            open: true
          }
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: {
            ...this.state.alert,
            message: "Unable to save! Try again later.",
            color: "error",
            open: true
          }
        });
      });
  };

  _deleteRole = () => {
    let { role } = this.state;
    let { history } = this.props;
    // add confirmation code here
    Axios.delete(`/roles/${role._id}`)
      .then(response => {
        let { data } = response;
        // <Redirect to="/roles" />;
        history.replace("/roles");
      })
      .catch(err => {
        console.log(err);
      });
  };
  // life cycle events

  async componentDidMount() {
    let { roleId } = this.props.match.params;

    try {
      Axios.get(`/roles/${roleId}/users?page=1&limit=25`).then(
        ({ data: users }) => {
          this.setState({
            users: users.data
          });
        }
      );

      let [{ data: defaultPermissions }, { data: role }] = await Promise.all([
        Axios.get("/roles/default-permissions"),
        Axios.get("/roles/" + roleId)
      ]);
      // to merge objects by key with modified values
      let rolesData = role.data.permissions;
      // take default permissions and add roles permission values
      let rolePermissions =
        Array.isArray(defaultPermissions.data) && Array.isArray(rolesData)
          ? defaultPermissions.data.map((module, index) => {
              let roleModuleData = rolesData.filter(
                mod => mod.moduleKeyName === module.moduleKeyName
              );
              roleModuleData =
                roleModuleData.length > 0 &&
                !Array.isArray(roleModuleData[0].actions)
                  ? roleModuleData[0]
                  : null;

              if (!roleModuleData) return module;

              module.actions = {
                ...module.actions,
                ...roleModuleData.actions
              };
              return module;
            })
          : defaultPermissions.data;

      // let rolePermissions = {
      //   ...defaultPermissions.data,
      //   ...role.data.permissions
      // };
      //{
      //   ...defaultPermissions.data,
      //   ...role.data.permissions
      // };

      // console.log(rolePermissions);
      // rolePermissions = objectToArray(rolePermissions);
      this.setState({
        defaultPermissions: defaultPermissions.data,
        rolePermissions,
        originalRolePermissions: [...rolePermissions],
        role: role.data
      });
    } catch (err) {
      console.log(err);
    }
  }

  // lifecylce events ends

  _createUserCard = users => {
    return users.map((user, index) => (
      <Grid item xs={12} className="userBox" key={`userKey${index}`}>
        <Grid container spacing={2} className="userListing">
          <Grid item xs={12} className="mB10">
            <Button component="span" className="userDelete">
              <DeleteForeverIcon />
            </Button>
            <figure>
              <img
                alt="profile_pic"
                src={
                  (user.user &&
                    user.user.avatar &&
                    `${Base_URL}${user.user.avatar}`) ||
                  avatarPlaceholder
                }
              />
            </figure>

            <div className="cardDetails">
              <h2>
                {user.user && user.user.name} ({user.employeeCode})
              </h2>

              <div className="empDetails">
                <p>Designation: {user.designation && user.designation.title}</p>
                <p>Department: {user.department && user.department.title}</p>
              </div>

              {/* {user.employeeCode}
              {user.user.name}
              {user.department}
              {user.designation} */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  _renderPermissions(rolePermissions = [], readonly = true) {
    let { expanded } = this.state;

    return rolePermissions.map((module, index) => {
      let { actions } = module;

      return (
        <TableRow key={"pemissionAccordian" + index}>
          <TableCell component="td" scope="row" colSpan="" className="p-0">
            <Typography component="label" className="permissionName">
              {module.moduleName}
            </Typography>
          </TableCell>
          <TableCell className="permissionList">
            {Object.entries(actions).map(
              ([thisKey, thisValue], actionIndex) => {
                return (
                  <div
                    className="permissionCollaps"
                    key={`action${actionIndex}`}
                  >
                    <Typography
                      component="label"
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}
                    >
                      {thisKey && thisKey.split("_").join(" ")
                      /* .toUpperCase() */
                      }
                    </Typography>
                    <FormControlLabel
                      className="cssCheck"
                      aria-label="Acknowledge"
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={thisValue}
                          onChange={() => {
                            let permissions = JSON.parse(
                              JSON.stringify(rolePermissions)
                            );
                            try {
                              permissions[index].actions[thisKey] = !thisValue;
                              this.setState({
                                rolePermissions: permissions
                              });
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                        />
                      }
                    />
                  </div>
                );
              }
            )}
            {/* 
            {actions.map((action, actionIndex) => {
              let thisKey = Object.keys(action)[0];
              let thisValue = Object.values(action)[0];
              return (
                <div className="permissionCollaps" key={`action${actionIndex}`}>
                  <Typography
                    component="label"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                  >
                    {thisKey && thisKey.split("_").join(" ")
                    
                    }
                  </Typography>
                  <FormControlLabel
                    className="cssCheck"
                    aria-label="Acknowledge"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={thisValue}
                        onChange={() => {
                          let permissions = JSON.parse(
                            JSON.stringify(rolePermissions)
                          );
                          try {
                            permissions[index].actions[actionIndex][
                              thisKey
                            ] = !thisValue;
                            this.setState({
                              rolePermissions: permissions
                            });
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      />
                    }
                  />
                </div>
              );
            })} */}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    let { rolePermissions, role, users, alert } = this.state;
    return (
      <div style={{ maxWidth: "100%" }} className="tableTitle">
        <h1>Edit Role and Permissions</h1>

        <div className="oflowH mB15">
          <CustomBreadcrumb
            data={[
              { heading: "Dashboard", link: "/" },
              { heading: "Roles and Permissions", link: "/roles" },
              { heading: `Edit ${role.name}` }
            ]}
          />

          <div className="btnConatinerR">
            <input
              type="text"
              placeholder="Search users"
              className="userSearch"
            />
            <IconButton
              type="submit"
              aria-label="search"
              className="searchIcon"
            >
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleClickOpen}
            >
              Add Employee
            </Button>
            <Dialog open={this.state.open} onClose={this.handleClose}>
              <div className="roleModal">
                <h3>Add Employee</h3>
                <div>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    multiple
                    placeholder="Add Employee"
                    options={top100Films.map(option => option.title)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="autoComplete"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>

              <DialogActions className="p-0 pT15">
                <Button
                  onClick={this.submitForm}
                  variant="contained"
                  color="primary"
                  className=""
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {isAuthorised("role_permissions.delete") && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleClickOpen1}
                // onClick={this._deleteRole}
              >
                Delete Role
              </Button>
            )}
            <Dialog open={this.state.open1} onClose={this.handleClose1}>
              <div className="roleModal">
                <h3>Are you sure?</h3>
                <p>You want to delete the role permanently?</p>
              </div>
              <DialogActions className="p-0 pT15">
                <Button
                  variant="contained"
                  color="primary"
                  className=""
                  onClick={this.handleClose1}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this._deleteRole}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Grid container spacing={2} className="medicalTable">
          <Grid item xs={9}>
            <Table aria-label="simple table" className="permTable">
              <TableHead>
                <TableRow>
                  <TableCell component="td" colSpan="2" className="bg-white">
                    <div className="permissionHead">
                      <label>
                        Permission / Role <span>&nbsp;&nbsp;({role.name})</span>
                      </label>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this._renderPermissions(rolePermissions, false)}
                <TableRow>
                  <TableCell colSpan="2">
                    <div className="permissionBtn">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this._savePermissions}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        className="mL20"
                        onClick={this._resetForm}
                      >
                        Cancel
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={3}>
            <div className="userCards">
              <h4>User Listing</h4>
              <Grid container spacing={2} className="userCardBlock">
                {/* user card */}
                {this._createUserCard(users)}
                {/* {this._createUserCard([{ user: "5240" }, { user: "5240" }])} */}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={alert.open}
          autoHideDuration={3000}
          onClose={this.handleAlertClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          message={alert.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleAlertClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        ></Snackbar>
      </div>
    );
  }
}
