import React from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Grid, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { withFormik } from "formik";
import moment from "moment";

const TableDateFilter = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleFilterSubmit
  } = props;

  function handleInnerFilterSubmit() {
    let params = {
      startDate: values.dateFrom,
      endDate: values.dateTo
    };
    handleFilterSubmit(params);
  }
  return (
    <Grid container className="pT15 pB15 box-border mB15" spacing={0}>
      <Grid item xs={12} className="">
        <Grid container>
          <Grid item xs={4} md={2} lg={2} xl={2} className="formContainer">
            <div className="input-group d-inline-block">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="From"
                  label={false}
                  className="picker-input"
                  name="dateFrom"
                  value={values.dateFrom}
                  onChange={e => {
                    setFieldValue(
                      "dateFrom",
                      moment(e)
                        .startOf("day")
                        .toISOString()
                    );
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={4} md={2} lg={2} xl={2} className="formContainer">
            <div className="input-group d-inline-block">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="To"
                  label={false}
                  className="picker-input filter-minDate-error"
                  name="dateTo"
                  minDate={values.dateFrom}
                  value={values.dateTo}
                  onChange={e => {
                    setFieldValue(
                      "dateTo",
                      moment(e)
                        .startOf("day")
                        .toISOString()
                    );
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={4} md={2} lg={2} xl={2} className="formContainer">
            <div className="btnTableFilter">
              <Button
                variant="contained"
                color="primary"
                onClick={handleInnerFilterSubmit}
              >
                Go
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const formikTableDateFilterForm = withFormik({
  mapPropsToValues: () => ({
    dateFrom: null,
    dateTo: null
  })
})(TableDateFilter);

export default formikTableDateFilterForm;
