import React from "react";
// import PropTypes from "prop-types";
import { withFormik } from "formik";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";

import {
  Grid,
  Button,

} from "@material-ui/core";

import { ErrorMessage, FieldArray } from "formik";

function trainingForm(props) {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable
  } = props;

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="trainings"
        render={arrayHelpers =>
          values.trainings && values.trainings.length > 0 ? (
            values.trainings.map((trainings, index) => (
              <React.Fragment key={index}>
                <Grid item xl={11} lg={11} md={11} xs={11}>
                  <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Program Name* </label>
                        <input
                          type="text"
                          placeholder="Enter program name"
                          className="form-input"
                          name={`trainings[${index}].program_name`}
                          {...getFieldProps(`trainings[${index}].program_name`)}
                          value={values.trainings[index].program_name || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`trainings[${index}].program_name`}
                          className="error-text"
                          value={
                            touched.trainings &&
                            touched.trainings[index] &&
                            touched.trainings[index].program_name &&
                            errors.trainings &&
                            errors.trainings[index] &&
                            errors.trainings[index].program_name
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Conducted by </label>
                        <input
                          type="text"
                          placeholder="Enter conducted by"
                          className="form-input"
                          name={`trainings[${index}].conducted_by`}
                          {...getFieldProps(`trainings[${index}].conducted_by`)}
                          value={values.trainings[index].conducted_by || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`trainings[${index}].conducted_by`}
                          className="error-text"
                          value={
                            touched.trainings &&
                            touched.trainings[index] &&
                            touched.trainings[index].conducted_by &&
                            errors.trainings &&
                            errors.trainings[index] &&
                            errors.trainings[index].conducted_by
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Year* </label>
                        <input
                          type="text"
                          placeholder="Enter year"
                          className="form-input"
                          name={`trainings[${index}].year`}
                          {...getFieldProps(`trainings[${index}].year`)}
                          value={values.trainings[index].year || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`trainings[${index}].year`}
                          className="error-text"
                          value={
                            touched.trainings &&
                            touched.trainings[index] &&
                            touched.trainings[index].year &&
                            errors.trainings &&
                            errors.trainings[index] &&
                            errors.trainings[index].year
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Duration (In Days)* </label>
                        <input
                          type="text"
                          placeholder="Enter duration"
                          className="form-input"
                          name={`trainings[${index}].duration`}
                          {...getFieldProps(`trainings[${index}].duration`)}
                          value={values.trainings[index].duration || ""}
                          disabled={!isEditable && !selfEditable}
                        />
                        <ErrorMessage
                          component="p"
                          name={`trainings[${index}].duration`}
                          className="error-text"
                          value={
                            touched.trainings &&
                            touched.trainings[index] &&
                            touched.trainings[index].duration &&
                            errors.trainings &&
                            errors.trainings[index] &&
                            errors.trainings[index].duration
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      xl={2}
                      className="formContainer"
                    >
                      <div className="input-group btnConatinerR floatL">
                        <label>&nbsp;</label>
                        <Button
                          className="removeBtn mT8"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          disabled={!isEditable && !selfEditable}
                        >
                          -
                        </Button>
                        {index === values.trainings.length - 1 ? (
                          <Button
                            className="addBtn mT8 mL10"
                            onClick={() =>
                              arrayHelpers.insert(values.trainings.length, {
                                program_name: "",
                                conducted_by: "",
                                year: "",
                                duration: ""
                              })
                            }
                            disabled={!isEditable && !selfEditable}
                          >
                            +
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Button
              variant="outlined"
              onClick={() => {
                console.log(values.trainings);
                arrayHelpers.push({
                  program_name: "",
                  conducted_by: "",
                  year: "",
                  duration: ""
                });
              }}
              disabled={!isEditable && !selfEditable}
              className="m-auto"
            >
              {/* show this when user has removed all friends from the list */}+
              Add New Training
            </Button>
          )
        }
      />
      {(isEditable || selfEditable) && (
        <Grid item xs={12} md={12} lg={12} xl={12} className="">
          <div className="permissionBtn pL0">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="mL20"
              onClick={props.resetForm}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const formiktrainingForm = withFormik({
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      trainings: formData.trainings || []
    };
    return initialValues;
  },

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.trainings,
  enableReinitialize: true,

  handleSubmit: (values, formikBag) => {
    values.trainings = values.trainings || null;
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },
  displayName: "Training"
})(trainingForm);

export default formiktrainingForm;
