import React from "react";
import CustomBreadcrumb from "../../components/breadcrumb";
import PropTypes from "prop-types";
import LeaveSummary from "./LeaveSummary";

import {
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  Button
} from "@material-ui/core";
import LeaveHistory from "../leaves/LeaveHistory";
import Attendance from "../leaves/Attendance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${"index"}`
  };
}

export default function Dashboard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>HR Dashboard</h1>
      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "HR Dashboard", link: "/hr/dashboard" }
          ]}
        />
      </div>
      <div className="tabContainer leaveTab">
        <Tabs
          value={value}
          onChange={handleChange}
          component="div"
          className="tabList tabPaneTable"
        >
          <Tab label="Leave Summary" wrapped {...a11yProps(0)} />
          <Tab label="Leave History" {...a11yProps(1)} />
          <Tab label="Attendance History" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0} component="div" className="tabTable">
          <LeaveSummary />
        </TabPanel>
        <TabPanel value={value} index={1} component="div" className="tabTable">
          <LeaveHistory showAll={false} />
        </TabPanel>
        <TabPanel value={value} index={2} component="div" className="tabTable">
          <Attendance showAll={false} />
        </TabPanel>
      </div>
    </div>
  );
}
