import React from "react";
import CustomBreadcrumb from "../../components/breadcrumb";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";


const PaySlip = () => {
  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Payslip</h1>

      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Payroll", link: "/" },
            { heading: "Payslip", link: "/" }
          ]}
        />
      </div>
      <div className="tabContainer pT15 pB15">
        <Grid container className="xs-pl15 pT15 pB15">
          <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
            <div className="input-group">
              <label>Select Year* </label>
              <select className="form-select">
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
            <div className="input-group">
              <label>Select Month* </label>
              <select className="form-select">
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={6} md={2} lg={2} xl={2} className="formContainer">
            <div className="permissionBtn pL0">
              
              <Button  variant="contained" className="mT8"
                  color="primary">Save</Button>
              
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default PaySlip;
