import React, { useState, useEffect } from "react";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import { Grid, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ErrorMessage, FieldArray, withFormik } from "formik";
// import clsx from "clsx";
import moment from "moment";
import Axios from "axios";

function withTechSkills(BaseComponent) {
  return function (props) {
    let [skillsOptions, setskillsOptions] = useState([]);

    function removeSkillOptions(index) {
      let skillsOptionsNew = skillsOptions.filter((skill, i) => i !== index);
      setskillsOptions(skillsOptionsNew);
    }

    async function getSkillsForTechnology(selectedTechId, index) {
      //console.log(index, selectedTechId)
      let { data: skillData } = await Axios.get(
        `/masters/skills/${selectedTechId}`
      );
      skillsOptions = [...skillsOptions];
      skillsOptions[index] = skillData.data;
      setskillsOptions(skillsOptions);
      return null;
    }

    return (
      <BaseComponent
        {...props}
        skillsOptions={skillsOptions}
        getSkillsForTechnology={getSkillsForTechnology}
        removeSkillOptions={removeSkillOptions}
      />
    );
  };
}

function SkillForm(props) {
  const {
    values,
    touched,
    errors,
    handleBlur,
    technologies = [],
    technologySkillMap = [],
    skillsOptions = [],
    getSkillsForTechnology,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    isEditable,
    selfEditable,
    apiCalled = {},
    getSkills,
  } = props;

  useEffect(() => {
    for (let index = 0; index < values.skills.length; index++) {
      getSkillsForTechnology(values.skills[index].technology._id, index);
    }
    
  }, []);

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="skills"
        render={(arrayHelpers) =>
          values.skills && values.skills.length > 0 ? (
            values.skills.map((skill, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xl={11} lg={11} md={11} xs={11}>
                    <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Technology Area* </label>
                          <select
                            className="form-select"
                            {...getFieldProps(`skills[${index}].technology`)}
                            onChange={(e) => {
                              getSkillsForTechnology(e.target.value, index);
                              setFieldValue(
                                `skills[${index}].technology`,
                                e.target.value
                              );
                              setFieldValue(`skills[${index}].skill`, "");
                            }}
                            value={
                              values.skills[index].technology &&
                              values.skills[index].technology._id
                            }
                            disabled={!isEditable && !selfEditable}
                          >
                            <option value="">Select</option>
                            {technologies.map((technology, index) => (
                              <option
                                key={`technology${index}`}
                                value={technology._id}
                              >
                                {technology.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            component="p"
                            name={`skills[${index}].technology`}
                            className="error-text"
                            value={
                              touched.skills &&
                              touched.skills[index] &&
                              touched.skills[index].technology &&
                              errors.skills &&
                              errors.skills[index] &&
                              errors.skills[index].technology
                            }
                          />
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Skill* </label>
                          <select
                            className="form-select"
                            id={`skillMap${index}`}
                            {...getFieldProps(`skills[${index}].skill`)}
                            disabled={!isEditable && !selfEditable}
                            value={
                              values.skills[index].skill &&
                              values.skills[index].skill._id
                            }
                          >
                            <option value="">Select</option>
                            {skillsOptions[index] &&
                              skillsOptions[index].map((skill, skillIndex) => (
                                <option
                                  key={`skill${skillIndex}`}
                                  value={skill._id}
                                >
                                  {skill.name}
                                </option>
                              ))}
                          </select>
                          <ErrorMessage
                            component="p"
                            name={`skills[${index}].skill`}
                            className="error-text"
                            value={
                              touched.skills &&
                              touched.skills[index] &&
                              touched.skills[index].skill &&
                              errors.skills &&
                              errors.skills[index] &&
                              errors.skills[index].skill
                            }
                          />
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Year of Experience* </label>
                          <input
                            type="text"
                            className="form-input"
                            placeholder="Enter total year of experience"
                            name={`skills[${index}].year_of_exp`}
                            {...getFieldProps(`skills[${index}].year_of_exp`)}
                            value={values.skills[index].year_of_exp || ""}
                            disabled={!isEditable && !selfEditable}
                          />
                          <ErrorMessage
                            component="p"
                            name={`skills[${index}].year_of_exp`}
                            className="error-text"
                            value={
                              touched.skills &&
                              touched.skills[index] &&
                              touched.skills[index].year_of_exp &&
                              errors.skills &&
                              errors.skills[index] &&
                              errors.skills[index].year_of_exp
                            }
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Expertise Level* </label>{" "}
                          {/* // Expert Beginner Medium */}
                          <select
                            className="form-select"
                            {...getFieldProps(
                              `skills[${index}].competency_level`
                            )}
                            value={values.skills[index].competency_level || ""}
                            disabled={!isEditable && !selfEditable}
                          >
                            <option value="">Select</option>
                            <option key={`competency_level1`} value="Beginner">
                              Beginner
                            </option>
                            <option key={`competency_level2`} value="Medium">
                              Medium
                            </option>
                            <option key={`competency_level3`} value="Expert">
                              Expert
                            </option>
                          </select>
                          <ErrorMessage
                            component="p"
                            name={`skills[${index}].competency_level`}
                            className="error-text"
                            value={
                              touched.skills &&
                              touched.skills[index] &&
                              touched.skills[index].competency_level &&
                              errors.skills &&
                              errors.skills[index] &&
                              errors.skills[index].competency_level
                            }
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Last Used </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-dialog"
                              placeholder="Select date"
                              label={false}
                              autoOk={true}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              className="picker-input"
                              value={values.skills[index].last_used || null}
                              name={`skills[${index}].last_used`}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue(
                                  `skills[${index}].last_used`,
                                  moment(e).toISOString()
                                );
                              }}
                              disabled={!isEditable && !selfEditable}
                            />
                          </MuiPickersUtilsProvider>
                          <ErrorMessage
                            component="p"
                            name={`skills[${index}].last_used`}
                            className="error-text"
                            value={
                              touched.skills &&
                              touched.skills[index] &&
                              touched.skills[index].last_used &&
                              errors.skills &&
                              errors.skills[index] &&
                              errors.skills[index].last_used
                            }
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="formContainer"
                      >
                        <div className="input-group btnConatinerR floatL">
                          <label>&nbsp;</label>
                          <Button
                            className="removeBtn mT8"
                            onClick={() => {
                              arrayHelpers.remove(index);
                              props.removeSkillOptions(index);
                            }}
                            disabled={!isEditable && !selfEditable}
                          >
                            -
                          </Button>
                          {index === values.skills.length - 1 ? (
                            <Button
                              className="addBtn mT8 mL10"
                              onClick={() =>
                                arrayHelpers.insert(values.skills.length, {
                                  technology: "",
                                  skill: "",
                                  year_of_exp: "",
                                  competency_level: "",
                                  last_used: "",
                                })
                              }
                              disabled={!isEditable && !selfEditable}
                            >
                              +
                            </Button>
                          ) : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                arrayHelpers.push({
                  technology: "",
                  skill: "",
                  year_of_exp: "",
                  competency_level: "",
                  last_used: "",
                })
              }
              disabled={!isEditable && !selfEditable}
              className="m-auto"
            >
              {/* show this when user has removed all friends from the list */}+
              Add New Skill
            </Button>
          )
        }
      />
      {(isEditable || selfEditable) && (
        <Grid item xs={12} md={12} lg={12} xl={12} className="">
          <div className="permissionBtn pL0">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="mL20"
              onClick={props.resetForm}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const formikskillForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      skills: formData.skills || [],
    };
    return initialValues;
  },

  //adding validation schema
  validationSchema: addEmployeeValidationSchema.skills,
  enableReinitialize: true,

  handleSubmit: (values, formikBag) => {
    values.skills = values.skills || null;
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },
  displayName: "Skill",
})(withTechSkills(SkillForm));

export default formikskillForm;
